<!-- <div class="row">
  <div class="col-xxl-5">
    <div class="row">
      <div class="col-md-6">
        <ngx-profit-card></ngx-profit-card>
      </div>
      <div class="col-md-6">
        <ngx-earning-card></ngx-earning-card>
      </div>
    </div>

    <ngx-traffic-reveal-card></ngx-traffic-reveal-card>
  </div>

  <div class="col-xxl-7">
    <ngx-ecommerce-charts></ngx-ecommerce-charts>
  </div>
</div>

<div class="row">
  <div class="col-xxl-9">
    <ngx-country-orders></ngx-country-orders>
  </div>

  <div class="col-xxl-3">
    <ngx-progress-section></ngx-progress-section>
  </div>
</div>

<div class="row">
  <div class="col-xxl-9">
    <ngx-ecommerce-visitors-analytics></ngx-ecommerce-visitors-analytics>
  </div>

  <div class="col-xxl-3">
    <ngx-user-activity></ngx-user-activity>
  </div>
</div> -->


<nb-card class="small-header smart-table" style="height: 100%; overflow: hidden;">
    <nb-card-body style="overflow: hidden;">
        <ngx-smart-bot id="large-smart-bot"></ngx-smart-bot>
    </nb-card-body>
</nb-card>