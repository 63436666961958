<nb-card>
  <nb-card-header>Room Management</nb-card-header>
  <div class="room-selector">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         [attr.viewBox]="viewBox" preserveAspectRatio="xMidYMid">
      <defs>

        <filter id="f2" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur result="blurOut" in="StrokePaint" stdDeviation="3"/>
        </filter>

        <pattern id="New_Pattern_Swatch_1" data-name="New Pattern Swatch 1" width="60" height="60"
                 patternUnits="userSpaceOnUse" viewBox="0 0 60 60">
          <line class="stroke-pattern" x1="-113.26" y1="123.26" x2="3.26" y2="6.74"/>
          <line class="stroke-pattern" x1="-103.26" y1="133.26" x2="13.26" y2="16.74"/>
          <line class="stroke-pattern" x1="-93.26" y1="143.26" x2="23.26" y2="26.74"/>
          <line class="stroke-pattern" x1="-83.26" y1="153.26" x2="33.26" y2="36.74"/>
          <line class="stroke-pattern" x1="-73.26" y1="163.26" x2="43.26" y2="46.74"/>
          <line class="stroke-pattern" x1="-63.26" y1="173.26" x2="53.26" y2="56.74"/>
          <line class="stroke-pattern" x1="-53.26" y1="123.26" x2="63.26" y2="6.74"/>
          <line class="stroke-pattern" x1="-43.26" y1="133.26" x2="73.26" y2="16.74"/>
          <line class="stroke-pattern" x1="-33.26" y1="143.26" x2="83.26" y2="26.74"/>
          <line class="stroke-pattern" x1="-23.26" y1="153.26" x2="93.26" y2="36.74"/>
          <line class="stroke-pattern" x1="-13.26" y1="163.26" x2="103.26" y2="46.74"/>
          <line class="stroke-pattern" x1="-3.26" y1="173.26" x2="113.26" y2="56.74"/>
          <line class="stroke-pattern" x1="6.74" y1="123.26" x2="123.26" y2="6.74"/>
          <line class="stroke-pattern" x1="16.74" y1="133.26" x2="133.26" y2="16.74"/>
          <line class="stroke-pattern" x1="26.74" y1="143.26" x2="143.26" y2="26.74"/>
          <line class="stroke-pattern" x1="36.74" y1="153.26" x2="153.26" y2="36.74"/>
          <line class="stroke-pattern" x1="46.74" y1="163.26" x2="163.26" y2="46.74"/>
          <line class="stroke-pattern" x1="56.74" y1="173.26" x2="173.26" y2="56.74"/>
          <line class="stroke-pattern" x1="-113.26" y1="63.26" x2="3.26" y2="-53.26"/>
          <line class="stroke-pattern" x1="-103.26" y1="73.26" x2="13.26" y2="-43.26"/>
          <line class="stroke-pattern" x1="-93.26" y1="83.26" x2="23.26" y2="-33.26"/>
          <line class="stroke-pattern" x1="-83.26" y1="93.26" x2="33.26" y2="-23.26"/>
          <line class="stroke-pattern" x1="-73.26" y1="103.26" x2="43.26" y2="-13.26"/>
          <line class="stroke-pattern" x1="-63.26" y1="113.26" x2="53.26" y2="-3.26"/>
          <line class="stroke-pattern" x1="-53.26" y1="63.26" x2="63.26" y2="-53.26"/>
          <line class="stroke-pattern" x1="-43.26" y1="73.26" x2="73.26" y2="-43.26"/>
          <line class="stroke-pattern" x1="-33.26" y1="83.26" x2="83.26" y2="-33.26"/>
          <line class="stroke-pattern" x1="-23.26" y1="93.26" x2="93.26" y2="-23.26"/>
          <line class="stroke-pattern" x1="-13.26" y1="103.26" x2="103.26" y2="-13.26"/>
          <line class="stroke-pattern" x1="-3.26" y1="113.26" x2="113.26" y2="-3.26"/>
          <line class="stroke-pattern" x1="6.74" y1="63.26" x2="123.26" y2="-53.26"/>
          <line class="stroke-pattern" x1="16.74" y1="73.26" x2="133.26" y2="-43.26"/>
          <line class="stroke-pattern" x1="26.74" y1="83.26" x2="143.26" y2="-33.26"/>
          <line class="stroke-pattern" x1="36.74" y1="93.26" x2="153.26" y2="-23.26"/>
          <line class="stroke-pattern" x1="46.74" y1="103.26" x2="163.26" y2="-13.26"/>
          <line class="stroke-pattern" x1="56.74" y1="113.26" x2="173.26" y2="-3.26"/>
          <line class="stroke-pattern" x1="-113.26" y1="3.26" x2="3.26" y2="-113.26"/>
          <line class="stroke-pattern" x1="-103.26" y1="13.26" x2="13.26" y2="-103.26"/>
          <line class="stroke-pattern" x1="-93.26" y1="23.26" x2="23.26" y2="-93.26"/>
          <line class="stroke-pattern" x1="-83.26" y1="33.26" x2="33.26" y2="-83.26"/>
          <line class="stroke-pattern" x1="-73.26" y1="43.26" x2="43.26" y2="-73.26"/>
          <line class="stroke-pattern" x1="-63.26" y1="53.26" x2="53.26" y2="-63.26"/>
          <line class="stroke-pattern" x1="-53.26" y1="3.26" x2="63.26" y2="-113.26"/>
          <line class="stroke-pattern" x1="-43.26" y1="13.26" x2="73.26" y2="-103.26"/>
          <line class="stroke-pattern" x1="-33.26" y1="23.26" x2="83.26" y2="-93.26"/>
          <line class="stroke-pattern" x1="-23.26" y1="33.26" x2="93.26" y2="-83.26"/>
          <line class="stroke-pattern" x1="-13.26" y1="43.26" x2="103.26" y2="-73.26"/>
          <line class="stroke-pattern" x1="-3.26" y1="53.26" x2="113.26" y2="-63.26"/>
          <line class="stroke-pattern" x1="6.74" y1="3.26" x2="123.26" y2="-113.26"/>
          <line class="stroke-pattern" x1="16.74" y1="13.26" x2="133.26" y2="-103.26"/>
          <line class="stroke-pattern" x1="26.74" y1="23.26" x2="143.26" y2="-93.26"/>
          <line class="stroke-pattern" x1="36.74" y1="33.26" x2="153.26" y2="-83.26"/>
          <line class="stroke-pattern" x1="46.74" y1="43.26" x2="163.26" y2="-73.26"/>
          <line class="stroke-pattern" x1="56.74" y1="53.26" x2="173.26" y2="-63.26"/>
        </pattern>
      </defs>

      <g>
        <path class="room-border" [attr.d]="border.d" *ngFor="let border of roomSvg.borders" />
      </g>

      <g>
        <path class="stroked-element"
              [attr.fill]="getUrlPath('#New_Pattern_Swatch_1')"
              [attr.d]="strokedArea.d" *ngFor="let strokedArea of roomSvg.stokedAreas"/>
      </g>

      <g [attr.id]="room.id" [class.selected-room]="selectedRoom == room.id" *ngFor="let room of sortedRooms">
        <path class="room-bg" (click)="selectRoom(room.id)" [attr.d]="room.area.d" [style.filter]="isIE || isFirefox ? 'inherit': ''" />
        <path class="room-border" [attr.d]="room.border.d" />
        <path class="room-border room-border-glow" [attr.d]="room.border.d" [style.filter]="isIE || isFirefox ? 'inherit': ''" />
        <text class="room-text" (click)="selectRoom(room.id)" text-anchor="middle"
              [attr.x]="room.name.x" [attr.y]="room.name.y">{{room.name.text}}</text>
      </g>
    </svg>
  </div>
</nb-card>
