<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Warehouse.Warehouse.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Name" label="Common.name"
                  [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                    <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Branch" label="Common.branch"
                  [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                    <input type="text" nbInput fullWidth formControlName="Branch" placeholder="{{'Common.branch' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Code" label="Common.code"
                  [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                    <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'Tự động tạo...' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Description" label="Warehouse.account"
                  [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                    <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="AccAccount" label="Warehouse.account" [array]="array" [index]="i"
                  [allowCopy]="true" [required]="false" [touchedValidate]="false">
                    <ngx-select2 formControlName="AccAccount" [select2Option]="select2OptionForAccAccount" [data]="accountList">
                      </ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="FindOrder" label="Warehouse.findOrder"
                  [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                    <input type="text" nbInput fullWidth formControlName="FindOrder" placeholder="{{'Warehouse.findOrder' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero style="float: left;">{{'Common.addOne' | translate | headtitlecase}}</button>
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>{{'Common.save' | translate | headtitlecase}}</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
