<nb-card size="medium">
  <nb-card-body>
    <span class="h3 location">New York</span>
    <span class="date">Mon 29 May</span>

    <div class="today">
      <span class="today-temperature h1">20&deg;</span>
      <nb-icon icon="sun-outline" pack="eva" class="today-icon"></nb-icon>
    </div>

    <div class="today-details">
      <div class="parameter">
        <span class="caption parameter-name">max</span>
        <span class="parameter-value">23&deg;</span>
      </div>
      <div class="parameter">
        <span class="caption parameter-name">min</span>
        <span class="parameter-value">19&deg;</span>
      </div>
      <div class="parameter">
        <span class="caption parameter-name">wind</span>
        <span class="parameter-value">4 km/h</span>
      </div>
      <div class="parameter">
        <span class="caption parameter-name">hum</span>
        <span class="parameter-value">87%</span>
      </div>
    </div>

    <div class="weekly-forecast">
      <div class="day">
        <span class="caption">Sun</span>
        <i class="weather-icon ion-ios-cloudy-outline"></i>
        <span class="temperature">17&deg;</span>
      </div>
      <div class="day">
        <span class="caption">Mon</span>
        <i class="weather-icon ion-ios-sunny-outline"></i>
        <span class="temperature">19&deg;</span>
      </div>
      <div class="day">
        <span class="caption">Tue</span>
        <i class="weather-icon ion-ios-rainy-outline"></i>
        <span class="temperature">22&deg;</span>
      </div>
      <div class="day">
        <span class="caption">Wed</span>
        <i class="weather-icon ion-ios-partlysunny-outline"></i>
        <span class="temperature">21&deg;</span>
      </div>
    </div>
  </nb-card-body>
</nb-card>
