<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)"
  (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="medium-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon"
            [title]="title ? title : ('Sales.SimpleSalesVoucher.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)"
            [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <!-- <input type="hidden" formControlName="Code"> -->
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Object" label="Warehouse.previousBook" [array]="array"
                  [index]="i" [allowCopy]="false" [required]="true">
                  <ngx-select2 formControlName="Object" [select2Option]="select2ContactOption"
                    (selectChange)="onObjectChange(formItem, $event, i)"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="ObjectName" label="Common.name" [array]="array" [index]="i"
                  [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="ObjectName"
                    placeholder="{{'Common.name' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Code" label="Common.code" [array]="array" [index]="i"
                  [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="Code"
                    placeholder="{{'Common.code' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-9">
                <ngx-form-group [formGroup]="formItem" name="Title" label="Common.title" [array]="array" [index]="i"
                  [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="Title"
                    placeholder="{{'Common.title' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Title" label="Purchase.dateOfPurchase" [array]="array"
                  [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" [owlDateTime]="dateOfPurchase" [owlDateTimeTrigger]="dateOfPurchase" nbInput
                    fullWidth formControlName="DateOfPurchase"
                    placeholder="{{'Purchase.dateOfPurchase' | translate | headtitlecase}}">
                  <owl-date-time #dateOfPurchase></owl-date-time>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="IsPayment" label="Common.payment" [array]="array" [index]="i"
                  [allowCopy]="false" [required]="false">
                  <nb-select fullWidth formControlName="IsPayment" placeholder="{{'Common.choose' | translate | headtitlecase}}">
                    <nb-option value="0">{{'Common.noPayment' | translate | headtitlecase}}</nb-option>
                    <nb-option value="1">{{'Common.payment' | translate | headtitlecase}}</nb-option>
                  </nb-select>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="InvoiceStatus" label="Accounting.invoiceStatus" [array]="array" [index]="i"
                  [allowCopy]="false" [required]="false">
                  <nb-select fullWidth formControlName="InvoiceStatus" placeholder="{{'Accounting.invoiceStatus' | translate | headtitlecase}}" (selectedChange)="onInvoiceStatusChange($event, formItem)">
                    <nb-option value="INCLUDE">{{'Accounting.includeInvoice' | translate | headtitlecase}}</nb-option>
                    <nb-option value="NOT_INCLUDE">{{'Accounting.notIncludeInvoice' | translate | headtitlecase}}</nb-option>
                    <nb-option value="NO_INVOICE">{{'Accounting.noInvoice' | translate | headtitlecase}}</nb-option>
                  </nb-select>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="InvoiceTemplate" label="Accounting.invoiceTemplate" [array]="array" [index]="i"
                  [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="InvoiceTemplate"
                    placeholder="{{'Accounting.invoiceStatus' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="InvoiceSymbol" label="Accounting.invoiceSymbol" [array]="array" [index]="i"
                  [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="InvoiceSymbol"
                    placeholder="{{'Accounting.invoiceSymbol' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="InvoiceNumber" label="Accounting.invoiceNumber" [array]="array" [index]="i"
                  [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="InvoiceNumber"
                    placeholder="{{'Accounting.invoiceSymbol' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="InvoiceDate" label="Accounting.invoiceDate" [array]="array" [index]="i"
                  [allowCopy]="false" [required]="false">
                  <input type="text" [owlDateTime]="invoiceDate" [owlDateTimeTrigger]="invoiceDate" nbInput
                    fullWidth formControlName="InvoiceDate"
                    placeholder="{{'Accounting.invoiceDate' | translate | headtitlecase}}">
                  <owl-date-time #invoiceDate></owl-date-time>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Note" label="Common.note" [array]="array" [index]="i"
                  [allowCopy]="false" [required]="false">
                  <textarea nbInput fullWidth formControlName="Note"
                    placeholder="{{'Common.note' | translate | headtitlecase}}" rows="4"></textarea>
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="SubNote" label="Common.subNote" [array]="array" [index]="i"
                  [allowCopy]="false" [required]="false">
                  <textarea nbInput fullWidth formControlName="SubNote"
                    placeholder="{{'Common.subNote' | translate | headtitlecase}}" rows="4"></textarea>
                </ngx-form-group>
              </div>
            </div>

            <!-- Detail form -->
            <!-- <hr> -->
            <label class="label">{{'Voucher.productOrservice' | translate | headtitlecase}}</label>
            <div class="row">
              <div class="col-sm-1 label">
                <div class="row">
                  <div class="col-sm-6">{{'Common.noNumber' | translate | headtitlecase}}</div>
                  <div class="col-sm-6">{{'Common.photo' | translate | headtitlecase}}</div>
                </div>
              </div>
              <div class="col-sm-4 label">{{'Voucher.productOrservice' | translate | headtitlecase}}</div>
              <div class="col-sm-7 label">
                <div class="row">
                  <div class="col-sm-2 label align-right">{{'Voucher.quantity' | translate | headtitlecase}}</div>
                  <div class="col-sm-2 label">{{'Voucher.unit' | translate | headtitlecase}}</div>
                  <div class="col-sm-2 label align-right">{{'Voucher.price' | translate | headtitlecase}}</div>
                  <div class="col-sm-2 label align-right">{{'Voucher.tax' | translate | headtitlecase}}</div>
                  <div class="col-sm-3 label align-right">{{'Voucher.toMoney' | translate | headtitlecase}}</div>
                  <div class="col-sm-1 label">CM</div>
                </div>
              </div>
            </div>

            <div formArrayName="Details" class="form-details" [sortablejs]="getDetails(formItem)"
              [sortablejsOptions]="{ handle: '.sorting-handle' }">
              <!-- <div formArrayName="Details" class="form-details"> -->
              <hr>
              <div class="form-detail-item" *ngFor="let detail of getDetails(formItem).controls; let ic=index"
                [formGroup]="detail">

                <div class="row">
                  <div class="col-lg-1">
                    <div class="row">
                      <div class="col-md-6 col-sm-3 col-xs-3">
                        <div class="form-group">
                          <label class="label">{{'Common.noNumber' | translate | headtitlecase}}</label>
                          <input nbInput fullWidth disabled="true" value="{{ ic + 1 }}"
                            style="text-align: center; cursor: grab; width: 100%; height: 2.5rem; padding: 0;">
                          <input type="hidden" formControlName="Id">
                        </div>
                        <div class="form-group">
                          <label class="label">{{'Form.cm' | translate | headtitlecase}}</label>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-9 col-xs-9">
                        <ngx-form-group [hideLabel]="true" [formGroup]="detail" name="Image" label="Common.image"
                          [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                          <input type="button" nbInput fullWidth formControlName="Image"
                            placeholder="{{'Common.image' | translate | headtitlecase}}" style="
                          height: 2.5rem;
                          background-repeat: no-repeat;
                          background-size: cover;
                          background-position: center; color: transparent;"
                            [ngStyle]="{'background-image': 'url(' + (detail.get('Image')['thumbnail'] || 'assets/icon/eva/image-outline.svg') + ')'}"
                            class="sorting-handle">
                        </ngx-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="row">
                      <div class="col-md-12">
                        <ngx-form-group [hideLabel]="true" [formGroup]="detail" name="Product" label="Common.image"
                          [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                          <ngx-select2 formControlName="Product" [select2Option]="select2OptionForProduct"
                            (selectChange)="onSelectProduct(detail, $event)">
                          </ngx-select2>
                        </ngx-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7">
                    <div class="row">
                      <div class="col-md-2">
                        <ngx-form-group [hideLabel]="true" [formGroup]="detail" name="Quantity" label="Voucher.quantity"
                          [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Quantity"
                            placeholder="{{'Voucher.quantity' | translate | headtitlecase}}" class="align-right"
                            (keyup)="toMoney(formItem, detail)" [inputMask]="towDigitsInputMask">
                        </ngx-form-group>
                      </div>
                      <div class="col-md-2">
                        <ngx-form-group [hideLabel]="true" [formGroup]="detail" name="Unit" label="Voucher.unit"
                          [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="true">
                          <ngx-select2 formControlName="Unit" [data]="detail.get('Unit')['dataList']"
                            [select2Option]="select2OptionForUnit">
                          </ngx-select2>
                        </ngx-form-group>
                      </div>
                      <div class="col-md-2">
                        <ngx-form-group [hideLabel]="true" [formGroup]="detail" name="Price" label="Voucher.price"
                          [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Price"
                            placeholder="{{'Voucher.price' | translate | headtitlecase}}" class="align-right"
                            (keyup)="toMoney(formItem, detail)" currencyMask [options]="curencyFormat">
                        </ngx-form-group>
                      </div>
                      <div class="col-md-2">
                        <ngx-form-group [hideLabel]="true" [formGroup]="detail" name="Tax" label="Voucher.tax"
                          [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="Tax"
                            placeholder="{{'Voucher.tax' | translate | headtitlecase}}" class="align-right"
                            (keyup)="toMoney(formItem, detail)" currencyMask [options]="taxFormat">
                        </ngx-form-group>
                      </div>
                      <div class="col-md-3">
                        <ngx-form-group [hideLabel]="true" [formGroup]="detail" name="Price" label="Voucher.toMoney"
                          [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="true">
                          <input type="text" nbInput fullWidth formControlName="ToMoney" placeholder="{{'Voucher.toMoney' | translate | headtitlecase}}"
                            class="align-right" currencyMask [options]="curencyFormat">
                        </ngx-form-group>
                      </div>
                      <div class="col-md-1 col-sm-1 col-xs-1">
                        <div class="form-group">
                          <label class="label align-right">Remove</label>
                          <button nbButton status="danger" hero size="medium"
                            (click)="removeDetailGroup(formItem, detail, ic)"
                            style="width: 100%; margin-bottom: 3px; padding: 0">
                            <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12">
                  <button nbButton status="success" (click)="addDetailFormGroup(formItem)" size="medium" hero
                    [disabled]="isProcessing">
                    <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                    {{'Sales.addProduct' | translate | headtitlecase}}
                  </button>
                </div>
                <div class="col-lg-3 col-md-8 col-sm-12 col-xs-12"></div>
                <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col-md-2 col-sm-4 col-xs-4"></div>
                    <div class="col-md-2 col-sm-4 col-xs-4"></div>
                    <div class="col-md-2 col-sm-4 col-xs-4"></div>
                    <div class="col-md-3 col-sm-4 col-xs-4" style="text-align: right; font-weight: bold;">
                      {{'Voucher.total' | translate | headtitlecase}}</div>
                    <div class="col-md-3 col-sm-8 col-xs-8"
                      style="text-align: right; font-weight: bold; padding-right: 1.7rem;">
                      {{formItem.get('_total').value | currency:'VND'}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12" style="text-align: right;">

              </div>
            </div>
            <!-- End Detail form -->


          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
              <nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
            </button>
            <!-- <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button> -->
            <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero
              (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
            <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;"
              hero>{{'Common.save' | translate | headtitlecase}}</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
