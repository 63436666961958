<div class="dialog-wrap {{inputMode}}" #dialogWrap>
    <nb-card *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
        <nb-card-header>
            <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="data['Title'] || ('Collaborator.Commission.title' | translate:{definition: '', action: commonService.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body class="print-body" #printContent>
            <div id="print-area">
                <ngx-print-header></ngx-print-header>
                <div>
                    <div class="print-title">{{ 'Collaborator.Commission.title' | translate:{action: '', definition: ''} | uppercase }}
                    </div>
                    <div class="under-title">{{ data.Code }} - {{ data.CommissionTo | date:'short' }}</div>
                </div>
                <div>
                    <div class="under-line">{{'Common.contact' | translate | headtitlecase}} : {{renderValue(data.PublisherName)}}</div>
                    <!-- <div class="under-line">{{'Common.amount' | translate | headtitlecase}} : {{renderValue(data.Amount)}}</div> -->
                </div>
                <div>
                    <div>
                        <label>Danh sách phiếu đặt hàng được thưởng</label>
                        <table style="width: 100%;" class="print-voucher-detail-table">
                            <colgroup>
                                <!--STT-->
                                <col span="1" style="width: 1cm;">
                                <!--Phieu-->
                                <col span="1" style="width: 1cm;">
                                <!--Phieu-->
                                <col span="1" style="width: 1cm;">
                                <!--Dien giai-->
                                <col span="1" style="width: 8cm;">
                                <!--DVT-->
                                <col span="1" style="width: 4cm;">
                                <!--SL-->
                                <col span="1" style="width: 2cm; text-align: right;">
                                <!--DG-->
                                <col span="1" style="width: 2cm; text-align: right;">
                                <!--Thanh tien-->
                                <col span="1" style="width: 2cm; text-align: right;">
                            </colgroup>
                            <tr class="print-voucher-detail-header">
                                <th class="text-align-left">{{'Common.noNumber' | translate | headtitlecase}}</th>
                                <th class="text-align-left">{{'Common.voucher' | translate | headtitlecase}}</th>
                                <th class="text-align-left">{{'Common.product' | translate | headtitlecase}}</th>
                                <th class="text-align-left">{{'Common.description' | translate | headtitlecase}}</th>
                                <th class="text-align-left">{{'Product.unit' | translate | headtitlecase}}</th>
                                <th class="text-align-right">{{'Common.quantity' | translate | headtitlecase}}</th>
                                <th class="text-align-right">{{'Common.price' | translate | headtitlecase}}</th>
                                <th class="text-align-right">{{'Common.numOfMoney' | translate | headtitlecase}}</th>
                            </tr>
                            <tr class="print-voucher-detail-line" *ngFor="let detail of data.Orders; let i = index">
                                <td class="text-align-left">{{i + 1}}</td>
                                <td class="text-align-left"><a class="link" (click)="this.commonService.previewVoucher('CLBRTORDER', detail.Voucher)">{{renderValue(detail.Voucher)}}</a></td>
                                <td class="text-align-left">{{renderValue(detail.Product)}}</td>
                                <td class="text-align-left">{{renderValue(detail.Description)}}</td>
                                <td class="text-align-left">{{renderValue(detail.UnitLabel)}}</td>
                                <td class="text-align-right">{{detail.Quantity}}</td>
                                <td class="text-align-right">{{(detail.Price) | currency:'VND'}}</td>
                                <td class="text-align-right">{{(detail.Quantity * detail.Price) | currency:'VND'}}</td>
                            </tr>
                            <tr style="font-weight: bold;">
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td class="text-align-right">{{'Voucher.total' | translate | headtitlecase}}</td>
                                <td class="text-align-right">{{data.TotalCommissionAmount | currency:'VND'}}</td>
                            </tr>
                        </table>
                        
                    </div>
                </div>
                <div>
                    <div class="under-line">{{'Common.description' | translate | headtitlecase}}: {{renderValue(data.Description)}}</div>
                </div>
                <div style="display: flex; flex-wrap: wrap">
                    <div style="text-align: center; flex: 0 0 50%;">
                        <br> {{'Common.treasurer' | translate | headtitlecase}}
                        <br><br><br>
                    </div>
                    <div style="text-align: center; flex: 0 0 50%;">
                        <br> {{'Common.payer' | translate | headtitlecase}}
                        <br><br><br>
                    </div>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <div class="buttons-row">
                <button nbButton hero status="warning" (click)="prepareCopy(data);">
                    <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
                </button>
                <button nbButton hero status="danger" (click)="approvedConfirm(data);">{{(processMapList[i]?.nextStateLabel || '') | translate | headtitlecase}}<nb-icon pack="eva" icon="skip-forward"></nb-icon></button>
                <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
                    <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
                </button>
            </div>
        </nb-card-footer>
    </nb-card>
</div>