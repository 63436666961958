<div class="pos-wrap scrollable-container" [nbSpinner]="loading" [ngClass]="{'is-fullscreen': isFullscreenMode}" #commercePosGui>
    <nb-card class="small-header smart-table" class="dialog-wrap {{inputMode}}" [formGroup]="orderForm" [ngClass]="{'pos-returns': orderForm['voucherType'] == 'COMMERCEPOSRETURN'}">
        <nb-card-body>
            <div class="pos" style="width: 100%; height: 100%; display: flex; flex-direction: column;">
                <div class="pos-container scrollable-container" style="flex: 1; display: flex; flex-direction: row; width: 100%; overflow: hidden;">
                    <div class="order" style="display: flex; flex-direction: column; flex: 1;">

                        <div class="pos-search" style="display: flex; flex-direction: row; padding: 0.5rem; padding-bottom: 0;">
                            <div class="pos-search-input-wrap" style="flex: 1; position: relative;">
                                <input #Search id="posSearchInput" (focus)="autoBlur($event, 30000, 'posSearchInput')" (blur)="clearAutoBlur($event, 'posSearchInput')" (keydown)="onSearchInputKeydown($event)" (keyup)="onSearchInputKeyup($event)" type="text" nbInput status="{{orderForm['voucherType'] == 'COMMERCEPOSRETURN' ? 'danger' : 'success'}}" fieldSize="large" fullWidth [placeholder]="searchInputPlaceholder || 'F3 - Tìm hàng hóa theo số truy xuất, số nhận thức, sku, id, tên...'" class="pos-search-input">
                                <div #searchResultsRef id="searchResults" style="
                                        position: fixed;
                                        z-index: 1;
                                        left: 0px;
                                        top: 65px;
                                        background-color: var(--ck-color-base-background);
                                        border-radius: 5px;
                                    " [style.height]="searchResults && searchResults.length > 0 ? '80vh' : '1px'" [style.width]="searchResults && searchResults.length > 0 ? '100vw' : '1px'">
                                    <!-- <div style=""> -->
                                    <cdk-virtual-scroll-viewport #searchListViewport [itemSize]="150" style="height: 80vh;" class="scrollable-container">
                                        <div class="pos-search-result-item" *cdkVirtualFor="let item of searchResults; let i = index;" [ngClass]="{
                                                    'active': i == searchResultActiveIndex,
                                                    'not-same-pre': !searchResults[i-1] || item.Sku != searchResults[i-1].Sku,
                                                    'not-same-next': !searchResults[i+1] || item.Sku != searchResults[i+1].Sku
                                                }" (click)="onChooseProduct(item)">
                                            <div class="image" style="
                                                    background-repeat: no-repeat; 
                                                    background-size: cover;
                                                    width: 150px; 
                                                    height: 100%;
                                                    margin-right: 0.5rem" [style.backgroundImage]="'url('+item.FeaturePicture?.SmallImage+')'"></div>
                                            <div style="flex: 1;">
                                                <div style="font-weight: bold;">{{item.text}}</div>
                                                <div style="margin-top: 0.5rem;">SKU: {{item.Sku}}</div>
                                                <div>Vị trí: {{item.Container?.FindOrder || item.Container?.ContainerFindOrder}} - {{item.Container?.ShelfName}}</div>
                                                <!-- <div>ĐVT: {{(item.Unit || item.WarehouseUnit) | objecttext}}</div> -->
                                                <div style="font-weight: bold; font-size: 2rem; line-height: 2rem;">Giá: <span style="color: var(--danger);">{{item.Price | currency:'VND'}}</span><span style="color: var(--ck-color-base)">/</span><span style="color: var(--orange)">{{(item.Unit || item.WarehouseUnit) | objecttext}}</span></div>
                                            </div>
                                        </div>
                                    </cdk-virtual-scroll-viewport>
                                </div>
                            </div>
                            <div class="pos-search-button-wrap">
                                <button nbButton status="{{orderForm['voucherType'] == 'COMMERCEPOSRETURN' ? 'danger' : 'success'}}" class="pos-search-button" size="large" style="margin-left: 1rem; width: 3.3rem;">
                                    <nb-icon icon="search-outline"></nb-icon>
                                </button>
                            </div>
                        </div>
                        <div class="pos-search" style="padding: 0.5rem; padding-bottom: 0;">
                            <div class="pos-search-input-wrap" style="flex: 1; position: relative;">
                                <ngx-form-group [formGroup]="orderForm" name="Object" label="Khách hàng" [allowCopy]="false" [required]="true" [hideLabel]="true">
                                    <ngx-select2 #customerEle class="" formControlName="Object" [select2Option]="select2OptionForContact" (selectChange)="onObjectChange(orderForm, $event)"></ngx-select2>
                                </ngx-form-group>
                            </div>
                        </div>
                        <div *ngIf="orderForm['voucherType'] == 'COMMERCEPOSRETURN'" style="padding: 0.5rem; color: var(--danger); font-weight: bold;">BẠN ĐANG THAO TÁC TRÊN PHIẾU TRẢ HÀNG {{orderForm.value?.Order ? 'CHO ĐƠN HÀNG' + ' ' + orderForm.value?.Order : ''}}</div>
                        <div *ngIf="orderForm.value?.State == 'UNRECORDED'" style="padding: 0.5rem; color: var(--danger); font-weight: bold;">PHIẾU ĐÃ HỦY</div>
                        <div *ngIf="orderForm.value?.RelativeVouchers && orderForm.value?.RelativeVouchers?.length > 0" style="padding: 1rem; color: var(--primary); font-weight: bold;">
                            <div>Chứng từ liên quan:</div>
                            <div style="cursor: pointer;" class="tag" *ngFor="let relativeVoucher of orderForm.value?.RelativeVouchers" (click)="preview(relativeVoucher.type+'80', relativeVoucher.id)" nbTooltip="{{relativeVoucher.type}}: {{relativeVoucher.text}}">{{relativeVoucher.id}}</div>
                        </div>

                        <div class="pos-order-detail scrollable-container" style="flex: 1; padding: 0.5rem;">
                            <table #orderDetailTable style="width: 100%; font-size: 1rem; font-weight: bold;">
                                <thead>
                                    <td style="text-align: center;">Xóa</td>
                                    <td style=" white-space: nowrap;">Hàng hóa</td>
                                    <td style="text-align: center;">(-)</td>
                                    <td style="text-align: right;">Số lượng X Đơn giá<br>= Thành tiền</td>
                                    <td style="text-align: center;">(+)</td>
                                </thead>
                                <tr *ngFor="let detail of getDetails(orderForm).controls; let i=index" [formGroup]="detail" [ngClass]="{'detail-active': detail['isActive']}" [id]="'detail-'+index" (click)="activeDetail(orderForm, detail, index)">
                                    <td style="text-align: center">
                                        <div style="display: flex; flex-direction: column;">
                                            <div>
                                                <button nbButton status="danger" size="large" style="flex-direction: column; font-size: 0.6rem; padding: 0.4rem;" (click)="removeDetail(orderForm, i)">
                                                    <nb-icon icon="close-outline"></nb-icon>
                                                    Delete
                                                </button>
                                            </div>
                                            <div style="font-size: 2rem; padding: 1rem;">{{i+1}}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="display: flex; flex-direction: column;">
                                            <div style="width: 100%; height: 100%; flex: 1; height: 15rem;">
                                                <img (click)="previewGoodsThumbnail(detail)" class="pos-product-image" src="{{detail.value.Image?.SmallImage}}" [ngStyle]="{'height': ((12 - getDetails(orderForm).controls.length) > 4 ? (12 - getDetails(orderForm).controls.length) : 5)+'rem'}">
                                            </div>
                                            <div style="min-width: 10rem;">{{detail.value.Description | objecttext}} - SKU: {{detail.value.Sku}} - Vị trí: {{detail.value?.FindOrder}}</div>

                                            <div *ngIf="detail.value?.RelativeVouchers && detail.value?.RelativeVouchers?.length > 0">
                                                <div class="tag" *ngFor="let relativeVoucher of detail.value?.RelativeVouchers" nbTooltip="{{relativeVoucher.type}}: {{relativeVoucher.text}}">{{relativeVoucher.id}}</div>
                                            </div>

                                        </div>
                                    </td>
                                    <td style="text-align: center">
                                        <button nbButton status="warning" size="large" style="margin: 0.2rem" (click)="onDecreaseQuantityClick(orderForm, detail)">
                                            <nb-icon icon="minus-outline"></nb-icon>
                                        </button>
                                    </td>
                                    <td style="text-align: right;">
                                        <input class="pos-quantity" nbInput size="large" formControlName="Quantity" (focus)="autoBlur($event, 5000)" (blur)="clearAutoBlur($event)" (keydown)="onQuantityKeydown(orderForm, detail, $event, quantityFormat)" (keyup)="onQuantityChanged(orderForm, detail, $event, quantityFormat)">
                                        <div class="pos-unit" style="white-space: nowrap;">{{detail.value.Unit | objecttext}}</div>
                                        <div *ngIf="detail.value.AccessNumbers && detail.value.AccessNumbers.length > 0" class="pos-access-numbers">Số truy xuất: {{detail.value.AccessNumbers | objectstext}}</div>
                                        <div class="pos-price">x {{detail.value.Price | currency:'VND'}}</div>
                                        <div class="pos-discount">Bớt khách quen: {{detail.value.Discount | number}} đ</div>
                                        <hr style="margin: 0.5rem;">
                                        <div class="pos-tomoney">= {{detail.value.ToMoney | currency:'VND'}}</div>
                                    </td>
                                    <td style="text-align: center">
                                        <button nbButton status="success" size="large" style="margin: 0.2rem" (click)="onIncreaseQuantityClick(orderForm, detail)">
                                            <nb-icon icon="plus-outline"></nb-icon>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="pos-business" style="display: flex; flex-direction: column; padding: 0.5rem;">

                        <div>
                            <div class="row">
                                <div class="col-xxxl-12">
                                    <div class="pos-info">
                                        <div *ngIf="orderForm.value?.Code" style="white-space: nowrap; font-size: 0.9rem;" [ngClass]="{'text-color-danger': (orderForm['voucherType'] == 'COMMERCEPOSRETURN' || orderForm.value['State'] == 'UNRECORDED'),'text-color-success':(orderForm['voucherType'] == 'COMMERCEPOSORDER' && orderForm.value['State'] != 'UNRECORDED')}">Mã đơn:<br>{{orderForm.value?.Code}}</div>
                                        <div *ngIf="!orderForm.value?.Code" style="white-space: nowrap; font-size: 0.9rem;" class="text-color-warning">Đang khởi tạo...</div>
                                        <div *ngIf="orderForm.value?.DateOfSale || orderForm.value?.DateOfReturn" style="white-space: nowrap; font-size: 0.9rem;" class="text-color-primary">{{(orderForm.value?.DateOfSale || orderForm.value?.DateOfReturn) | date:'short'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="scrollable-container" style="flex: 1; overflow-x: hidden; max-width: initial; margin: initial;">
                            <div class="row">
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" status="info" class="pos-business-button" (click)="chooseCustomer()">
                                        <nb-icon icon="person-outline"></nb-icon>
                                        <div class="button-label">Khách hàng</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="orderForm['isProcessing'] || orderForm.value?.State == 'APPROVED'" status="warning" *ngIf="orderForm.value?.IsDebt" class="pos-business-button" (click)="toggleDebt(orderForm)">
                                        <div>
                                            <nb-icon icon="book-outline"></nb-icon>
                                            <span style="font-weight: bold; font-size: 1rem">F7</span>
                                        </div>
                                        <div class="button-label">Công nợ</div>
                                    </button>
                                    <button nbButton fullWidth size="medium" status="primary" [disabled]="orderForm['isProcessing'] || orderForm.value?.State == 'APPROVED'" [outline]="true" *ngIf="!orderForm.value?.IsDebt" class="pos-business-button" (click)="toggleDebt(orderForm)">
                                        <div>
                                            <nb-icon icon="book-outline"></nb-icon>
                                            <span style="font-weight: bold; font-size: 1rem">F7</span>
                                        </div>
                                        <div class="button-label">Tiền mặt</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" status="danger" class="pos-business-button" (click)="onMakeNewReturnsForm()">
                                        <div>
                                            <nb-icon icon="undo-outline"></nb-icon>
                                            <span style="font-weight: bold; font-size: 1rem">F10</span>
                                        </div>
                                        <div class="button-label">Trả hàng</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" status="info" class="pos-business-button" (click)="openDeploymentForm(orderForm)">
                                        <nb-icon icon="archive-outline"></nb-icon>
                                        <div class="button-label">Triển khai</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" status="primary" class="pos-business-button" (click)="makeNewOrder()">
                                        <div>
                                            <nb-icon icon="plus-square-outline"></nb-icon>
                                            <span style="font-weight: bold; font-size: 1rem">F5</span>
                                        </div>
                                        <div class="button-label">Đơn mới</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="orderForm.value?.State !== 'APPROVED'" status="primary" class="pos-business-button" (click)="print(orderForm, {printType: 'PRICEREPORT'})">
                                        <div>
                                            <nb-icon icon="printer-outline"></nb-icon>
                                            <span style="font-weight: bold; font-size: 1rem">F9</span>
                                        </div>
                                        <div class="button-label">In lại</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Ghi chú</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Chiết khấu</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Thuế VAT</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Tiền phí</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Quà tặng</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Hàng hóa</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Tồn kho</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Nhân viên</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Đặt hàng</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Lưu tạm</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">--</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Mở rộng</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Đổi trả</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Báo cáo</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Mở két</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Khóa</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Đổi user</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Quản lý</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Fullscreen</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Tùy chọn</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style="padding-top: 1rem">
                            <div class="row pos-contact">
                                <div class="col-md-12" style="margin-bottom: 0.5rem;">
                                    <div class="pos-contact-field" style="padding-top: 10px">
                                        <nb-badge text="Số điện thoại (F8)" status="primary" position="top left" style="left: 5px;"></nb-badge>
                                        <input style="padding-left: 0.5rem; padding-right: 0.5rem" id="ObjectPhone" #ObjectPhone formControlName="ObjectPhone" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" (keyup)="onObjectPhoneInput(orderForm, $event)" type="text" nbInput status="primary" fieldSize="large" fullWidth placeholder="Số điện thoại..." class="pos-contact-field-input">
                                    </div>
                                </div>
                                <div class="col-md-12" style="margin-bottom: 0.5rem;">
                                    <div class="pos-contact-field" style="padding-top: 10px">
                                        <nb-badge text="Tên khách hàng (F8)" status="primary" position="top left" style="left: 5px;"></nb-badge>
                                        <input style="padding-left: 0.5rem; padding-right: 0.5rem" id="ObjectName" #ObjectName formControlName="ObjectName" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" type="text" nbInput (keyup)="onObjectNameInput(orderForm, $event)" status="primary" fieldSize="large" fullWidth placeholder="Tên khách hàng..." class="pos-contact-field-input">
                                    </div>
                                </div>
                                <div class="col-md-12" style="margin-bottom: 0.5rem;">
                                    <div class="pos-contact-field" style="padding-top: 10px; margin-bottom: 5px;">
                                        <nb-badge text="Địa chỉ (F8)" status="primary" position="top left" style="left: 5px;"></nb-badge>
                                        <input style="padding-left: 0.5rem; padding-right: 0.5rem" id="ObjectAddress" #ObjectAddress formControlName="ObjectAddress" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" type="text" nbInput (keyup)="onObjectNameInput(orderForm, $event)" status="primary" fieldSize="large" fullWidth placeholder="Địa chỉ..." class="pos-contact-field-input">
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="padding-bottom: 0.5rem;" *ngIf="orderForm['voucherType'] == 'COMMERCEPOSORDER'">
                                <div class="col-xxxl-12">
                                    <div class="pos-total-payment" style="text-align: right; margin-top: 5px; padding-top: 1rem; padding-bottom: 1rem; border-color: var(--info)">
                                        <nb-badge text="Tạm tính" status="info" position="top left" style="top: -5px; left: 5px; font-size: 0.8rem;"></nb-badge>
                                        <div style="color: var(--info)">{{orderForm.value?.Amount | currency:'VND'}}</div>
                                        <ng-container *ngIf="orderForm.value.State != 'APPROVED' && orderForm.value.Object?.id && orderForm.value.Object?.id != 'POSCUSTOMER'">
                                            <div *ngIf="orderForm['ReceivableDebt']" style="color: var(--warning);">Công nợ:<br>{{orderForm['ReceivableDebt'] | currency:'VND'}}</div>
                                        </ng-container>
                                        <div *ngIf="orderForm.value?.Returns">
                                            <!-- <ng-container *ngIf="!orderForm.value.Object?.id || orderForm.value.Object?.id == 'POSCUSTOMER'"> -->
                                            <div style="color: var(--warning);">{{orderForm['returnsObj'] && orderForm['returnsObj'].IsDebt ? 'Giảm nợ' : 'Hoàn tiền'}}:<br>{{orderForm.value.DebitFunds | currency:'VND'}}</div>
                                            <ng-container *ngIf="orderForm['returnsObj'] && orderForm['returnsObj'].IsDebt">
                                                <div style="color: var(--danger)">Tạm thu:<br>{{orderForm.value?.Amount | currency:'VND'}}</div>
                                            </ng-container>
                                            <ng-container *ngIf="!orderForm['returnsObj'] || !orderForm['returnsObj'].IsDebt">
                                                <div style="color: var(--danger)">{{orderForm.value?.Amount - orderForm.value.DebitFunds < 0 ? 'Tạm trả' : 'Tạm thu' }}:<br>{{(orderForm.value?.Amount - orderForm.value.DebitFunds < 0 ? -(orderForm.value?.Amount - orderForm.value.DebitFunds) : (orderForm.value?.Amount - orderForm.value.DebitFunds)) | currency:'VND'}}</div>
                                            </ng-container>
                                        </div>
                                        <!-- </ng-container> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" style="margin-bottom: 0.5rem;" *ngIf="orderForm['voucherType'] == 'COMMERCEPOSORDER'">
                                    <div class="pos-contact-field" style="padding-top: 10px; margin-bottom: 5px;">
                                        <nb-badge text="Bớt (F11)" status="success" position="top left" style="left: 5px;"></nb-badge>
                                        <nb-badge text="{{orderForm.value.Amount && orderForm.value.DecreaseForTotal && ((orderForm.value.DecreaseForTotal || 0)/orderForm.value.Amount*100).toFixed(2)}}%" status="danger" position="top right"></nb-badge>
                                        <input id="DecreaseForTotal" style="color: var(--success) !important; padding: 0.5rem;" #DecreaseForTotal formControlName="DecreaseForTotal" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" type="text" nbInput status="success" fieldSize="large" fullWidth placeholder="Bớt lấy thảo" class="pos-contact-field-input" currencyMask [options]="toMoneyCurencyFormat">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <ng-container *ngIf="orderForm['voucherType'] == 'COMMERCEPOSRETURN'">
                                        <div class="pos-total-payment" style="text-align: right; padding-top: 0.5rem; padding-bottom: 0.5rem; margin-bottom: 0.5rem; margin-top: 0.6rem;">
                                            <nb-badge text="{{orderForm.value?.IsDebt ? 'Giảm nợ' : 'Phải trả'}}" status="danger" position="top left" style="left: 5px; font-size: 0.8rem;"></nb-badge>
                                            <div>{{ orderForm.value?.Amount | currency:'VND'}}</div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="orderForm['voucherType'] == 'COMMERCEPOSORDER'">
                                        <div class="pos-total-payment" style="text-align: right; padding-top: 0.5rem; padding-bottom: 0.5rem; margin-bottom: 0.5rem; margin-top: 0.6rem;">
                                            <nb-badge text="{{orderForm['isReceipt'] ? (orderForm.value?.IsDebt ? 'Ghi nợ' : 'Phải thu') : (orderForm.value?.IsDebt ? 'Giảm nợ' : 'Phải trả')}}" status="danger" position="top left" style="left: 5px; font-size: 0.8rem;"></nb-badge>
                                            <div>{{ (orderForm['isReceipt']? (orderForm.value?.Amount - orderForm.value?.DecreaseForTotal - (orderForm['returnsObj'] && orderForm['returnsObj'].IsDebt ? 0 : orderForm.value?.DebitFunds)) : -(orderForm.value?.Amount - orderForm.value?.DecreaseForTotal - (orderForm['returnsObj'] && orderForm['returnsObj'].IsDebt ? 0 : orderForm.value?.DebitFunds))) | currency:'VND'}}</div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="col-md-12" style="margin-bottom: 0.5rem;" *ngIf="orderForm['voucherType'] == 'COMMERCEPOSORDER' && !orderForm.value?.IsDebt">
                                    <div class="pos-contact-field" style="padding-top: 10px">
                                        <nb-badge text="Khách đưa (F11)" status="primary" position="top left" style="left: 5px;"></nb-badge>
                                        <input id="CashReceipt" style="color: var(--primary) !important; padding: 0.5rem;" #CashReceipt formControlName="CashReceipt" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" type="text" nbInput status="primary" fieldSize="large" fullWidth placeholder="Khách đưa" class="pos-contact-field-input" currencyMask [options]="toMoneyCurencyFormat">
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="orderForm['voucherType'] == 'COMMERCEPOSORDER' && !orderForm.value?.IsDebt">
                                    <div class="pos-total-payment" style="text-align: right; padding-top: 0.5rem; padding-bottom: 0.5rem; border-color: var(--warning); margin-bottom: 0.5rem; margin-top: 0.6rem;">
                                        <nb-badge text="Thối lại" status="warning" position="top left" style="left: 5px;"></nb-badge>
                                        <div style="color: var(--warning)">{{orderForm.value?.CashReceipt - (orderForm.value?.Amount - orderForm.value?.DecreaseForTotal - (orderForm['returnsObj'] && orderForm['returnsObj'].IsDebt ? 0 : orderForm.value?.DebitFunds)) > 0 ? (orderForm.value?.CashReceipt - (orderForm.value?.Amount - orderForm.value?.DecreaseForTotal - (orderForm['returnsObj'] && orderForm['returnsObj'].IsDebt ? 0 : orderForm.value?.DebitFunds)) | currency:'VND') : '--'}}</div>
                                    </div>
                                </div>
                                <div class="col-xxxl-8">
                                    <button nbButton fullWidth size="medium" [disabled]="orderForm['isProcessing'] || orderForm.value?.State == 'APPROVED'" status="success" class="pos-business-button" (click)="payment(orderForm, {printType: 'PRICEREPORT'})">
                                        <div>
                                            <nb-icon icon="flash-outline"></nb-icon>
                                            <span style="font-weight: bold; font-size: 1rem">F9</span>
                                        </div>
                                        <div class="button-label">{{orderForm['voucherType'] == 'COMMERCEPOSRETURN' ? (orderForm.value?.IsDebt ? 'Giảm nợ' : 'Hoàn tiền') : (orderForm.value?.IsDebt ? 'Ghi nợ' : 'Thanh toán')}}</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="orderForm['isProcessing'] || orderForm.value?.State != 'APPROVED'" status="danger" class="pos-business-button" (click)="destroyOrder($event)">
                                        <div>
                                            <nb-icon icon="close-outline"></nb-icon>
                                            <span style="font-weight: bold; font-size: 1rem">F4</span>
                                        </div>
                                        <div class="button-label">Hủy phiếu</div>
                                    </button>
                                </div>
                            </div>
                            <div style="display: flex;">
                                <div style="flex: 1; padding: 0.1rem;">
                                    <button nbButton fullWidth style="flex-direction: column; padding: 0.5rem;" size="medium" status="warning" class="pos-business-button" (click)="onPreviousOrderClick()">
                                        <nb-icon icon="arrow-left-outline"></nb-icon>
                                    </button>
                                </div>
                                <div style="flex: 1; padding: 0.1rem;">
                                    <button nbButton fullWidth style="flex-direction: column; padding: 0.5rem" [disabled]="orderForm['isProcessing'] || historyOrderIndex == historyOrders.length - 1" size="medium" status="warning" class="pos-business-button" (click)="onNextOrderClick()">
                                        <nb-icon icon="arrow-right-outline"></nb-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pos-status">
                    <nb-progress-bar *ngIf="progress > 0" [value]="progress" [status]="progressStatus" size="tiny" style="margin: 0.5rem;">{{progressLabel}}</nb-progress-bar>
                    <div *ngIf="progress == 0" style="display: flex; align-items: center; padding: 0.2rem; padding-left: 0.5rem; padding-right: 0.5rem; white-space: nowrap;">
                        <div class="status" style="flex: 1;">Commerce POS v1.0 | Nhân viên bán hàng: {{(commonService.loginInfo$ | async)?.user?.Name}}</div>
                        <div class="pos-time" style="text-align: right; width: 350px;">{{currentDate | date:'short'}} - Trạng thái: {{ status || 'Ready'}}</div>
                    </div>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</div>