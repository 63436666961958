<!-- <select (change)="handleOnProvinceChange($event)" (focus)="onTouched()" [disabled]="isDisabled">
  <option>-- Select a province --</option>
  <option *ngFor="let item of data" [value]="item.id" [selected]="isSelect(item.id)">
    {{item.text}}</option>
</select> -->
<!-- <input type="hidden" [value]="fieldValue"> -->
<select2 *ngIf="_select2Option" #controls [disabled]="isDisabled" [ngClass]="{'disabled': isDisabled}"
  [value]="value"
  [data]="data"
  [options]="_select2Option"
  [cssImport]=true
  [disabled]="isDisabled"
  (valueChanged)="handleOnChange($event)"
  (onTouched)="handleOnTouched($event)"
  >
</select2>
