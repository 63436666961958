<div class="dialog-wrap {{inputMode}}" #dialogWrap>
    <nb-card [nbSpinner]="loading" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
        <nb-card-header>
            <ngx-card-header [option]="{controlOption: {}}" [size]="size" [icon]="favicon" [title]="(title | translate:{definition: '', action: commonService.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body class="print-body" #printContent>
            <div id="print-area" [formGroup]="choosedForms">
                <!-- <div class="blabel" *ngFor="let item of this.data; let i = index;" style="position: relative;"> -->
                <div class="blabel" *ngFor="let item of this.choosedForms.controls; let i = index;" style="position: relative;" [formGroup]="item">
                    <!-- <div class="find-order">{{item.Container.FindOrder || 'UNDEF'}}</div> -->
                    <div style="position:absolute; bottom: 0.5rem; right: 0.5rem;" class="print-choosed">
                        <nb-checkbox formControlName="Choosed"></nb-checkbox>
                    </div>
                    <div style="display: flex;">
                        <div style="flex: 1;">
                            <div class="access-number">Số truy xuất: {{item.value.AccessNumber}}</div>
                        </div>
                        <div>
                            <div class="product-price">{{item.value.Unit | objecttext}}({{item.value.Price}})</div>
                        </div>
                    </div>
                    <img class="bar-code" src="{{item.value.BarCode}}">
                    <!-- <div style="display: flex; height: 4.5mm; align-items: center; justify-content: center;">
                    </div> -->
                    <div class="info">SKU: {{item.value.Product.Sku}} - {{item.value.Product | objecttext}}</div>
                    <div style="display: flex; clear: both;">
                        <img class="qr-code" src="{{item.value.QrCode}}" style="margin-right: 0.5mm;">
                        <div class="register-info" style="flex: 1">{{registerInfo.companyName}}<br>ĐC: {{registerInfo.address}}<br>ĐT: {{registerInfo.tel}}<br>Web: {{registerInfo.website}}</div>
                    </div>
                </div>
                <div style="clear:both"></div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <div class="buttons-row">
                <button nbButton hero status="primary" (click)="print(0)">
                    <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                </button>
                <button nbButton hero status="danger" (click)="close()">
                    <nb-icon pack="eva" icon="close"></nb-icon>{{'Common.close' | translate | headtitlecase}}
                </button>
            </div>
        </nb-card-footer>
    </nb-card>
</div>