<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
    <!-- <audio #newDetailPipSound>
        <source src='assets/sounds/beep-08b.wav' type="audio/mp3">
    </audio>
    <audio #increaseDetailPipSound>
        <source src='assets/sounds/beep-07a.wav' type="audio/mp3">
    </audio>
    <audio #errorSound>
        <source src='assets/sounds/beep-03.wav' type="audio/mp3">
    </audio> -->
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card [nbSpinner]="isProcessing" class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Warehouse.InventoryAdjustNote.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <label class="label label-with-border-below">{{'Common.contactInfo' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-12">
                                <nb-tabset>
                                    <nb-tab tabTitle="{{'Common.mainInfo' | translate | headtitlecase}} (*)">
                                        <!-- <label class="label label-with-border-below">{{'Common.mainInfo' | translate | headtitlecase}}</label> -->
                                        <div class="row">
                                            <input type="hidden" formControlName="Code">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="Object" label="Voucher.organizationOrPersornal" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="Object" [select2Option]="select2OptionForContact" (selectChange)="onObjectChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectName" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectPhone" label="Common.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectPhone" placeholder="{{formItem.get('ObjectPhone').placeholder || ('Common.phone' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectIdentifiedNumber" label="Common.identifiedNumber" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectIdentifiedNumber" placeholder="{{'Common.identifiedNumber' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectAddress" label="Common.address" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectAddress" placeholder="{{formItem.get('ObjectAddress').placeholder || ('Common.address' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="ObjectEmail" label="Common.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="ObjectEmail" placeholder="{{formItem.get('ObjectEmail').placeholder || ('Common.email' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </nb-tab>
                                </nb-tabset>
                            </div>
                        </div>

                        <label class="label label-with-border-below">{{'Common.receivedAndPayment' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="ObjectBankName" label="Bank.name" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ObjectBankName" placeholder="{{'Bank.name' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="ObjectBankCode" label="Bank.code" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ObjectBankCode" placeholder="{{'Bank.code' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="DateOfAdjusted" label="{{validateVoucherDate(formItem.get('DateOfAdjusted'), 'Warehouse.dateOfAdjusted')}}" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                    <input type="text" [owlDateTime]="DateOfReceipted" [owlDateTimeTrigger]="DateOfReceipted" nbInput fullWidth formControlName="DateOfAdjusted" placeholder="{{'Warehouse.dateOfAdjusted' | translate | headtitlecase}}">
                                    <owl-date-time #DateOfReceipted [startAt]="formItem.get('DateOfAdjusted')?.value || this.commonService.lastVoucherDate"></owl-date-time>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-9">
                                <ngx-form-group [formGroup]="formItem" name="ReceiptAddress" label="Sales.deliveryAddress" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ReceiptAddress" placeholder="{{'Sales.deliveryAddress' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="RelativeVouchers" label="Common.relationVoucher" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <button class="fit-control-button" nbTooltip="Tạo phiếu cho nhiều người dùng kiểm kho" [outline]="true" nbButton status="primary" fullWidth (click)="createAlternateAdjustVoucher(formItem)" size="medium" hero>
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>Tạo phiếu phụ
                                    </button>
                                </ngx-form-group>
                            </div>

                            <div class="col-sm-12" *ngIf="formItem.get('RelativeVouchers').value?.length > 0">
                                <div class="form-group">
                                    <label class="label"><span class="valid">{{'Common.relativeVoucher' | translate | headtitlecase}}: </span></label>
                                    <div>
                                        <span class="tag" *ngFor="let relationVoucher of formItem.get('RelativeVouchers').value" nbTooltip="{{relationVoucher.type}}: {{relationVoucher | objectstext}}">
                                            <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher | objectid}}</span>
                                            <nb-icon pack="eva" icon="close-outline" class="close-btn" (click)="removeRelativeVoucher(formItem, relationVoucher)"></nb-icon>
                                        </span>
                                    </div>
                                    <div class="break"></div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="RelativeVouchers" label="Common.relationVoucher" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <button class="fit-control-button" [outline]="true" nbButton status="primary" fullWidth (click)="openRelativeVoucherChoosedDialog(formItem)" size="medium" hero>
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>Thêm chứng từ liên quan
                                    </button>
                                </ngx-form-group>
                            </div> -->

                            <div class="col-sm-9">
                                <ngx-form-group [formGroup]="formItem" name="Title" label="Common.title" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="Title" placeholder="{{'Common.title' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Shelf" label="Kệ hàng hóa" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                                    <ngx-select2 formControlName="Shelf" [select2Option]="select2OptionForShelf" [data]="shelfList"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <!-- <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Employee" label="Common.employee" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                                    <ngx-select2 formControlName="Employee" [select2Option]="selectEmployeeOption"></ngx-select2>
                                </ngx-form-group>
                            </div> -->

                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Note" label="Common.note" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <textarea class="scrollable-container" nbInput fullWidth formControlName="Note" placeholder="{{'Common.note' | translate | headtitlecase}}" rows="4"></textarea>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="SubNote" label="Common.subNote" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <textarea class="scrollable-container" nbInput fullWidth formControlName="SubNote" placeholder="{{'Common.subNote' | translate | headtitlecase}}" rows="3"></textarea>
                                </ngx-form-group>
                            </div>
                        </div>

                        <!-- Detail form -->
                        <label class="label">{{'Voucher.productOrservice' | translate | headtitlecase}}</label>
                        <div class="ag-grid-wrap" style="height: 300px; border: #ccc 1px solid; border-radius: 3px; overflow: hidden;">
                            <ag-grid-angular #agGrid style="width: 100%; height: 100%;" id="myGrid" class="ag-theme-balham{{themeName ? ('-'+themeName) : ''}}" [modules]="modules" [columnDefs]="columnDefs" [floatingFilter]="false" [debug]="true" [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [rowDeselection]="true" [rowModelType]="rowModelType" [paginationPageSize]="paginationPageSize" [cacheOverflowSize]="cacheOverflowSize" [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests" [infiniteInitialRowCount]="infiniteInitialRowCount" [maxBlocksInCache]="maxBlocksInCache" [cacheBlockSize]="cacheBlockSize" [getRowNodeId]="getRowNodeId" [components]="components" [rowData]="rowData" (gridReady)="onGridReady($event)" [multiSortKey]="multiSortKey" [rowDragManaged]="rowDragManaged" [getRowHeight]="getRowHeight" [rowHeight]="rowHeight" (columnResized)="onColumnResized()" (rowSelected)="onRowSelected()" [pagination]="pagination" [getRowStyle]="getRowStyle" (cellDoubleClicked)="cellDoubleClicked($event)">
                            </ag-grid-angular>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="buttons-row">
                                    <button nbButton status="success" (click)="addDetailFormGroup(formItem)" size="medium" hero [disabled]="isProcessing">
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>{{'Thêm dòng mới' | translate | headtitlecase}}
                                    </button>
                                    <button nbButton status="primary" (click)="addGoodsOfShelf(formItem)" size="medium" hero [disabled]="isProcessing" nbTooltip="Sau khi quét tất cả hàng hóa trên kệ thì click vào nút này để thêm các hàng hóa không còn xuất hiện nữa với số lượng bằng 0">
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>{{'Thêm thàng hóa theo kệ' | translate | headtitlecase}}
                                    </button>
                                    <button nbButton status="info" (click)="syncWithSubVoucher(formItem)" size="medium" hero [disabled]="isProcessing || (formItem.get('RelativeVouchers').value || []).length == 0">
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>{{'Đồng bộ phiếu phụ' | translate | headtitlecase}}
                                    </button>
                                    <button nbButton status="danger" (click)="barcodeScan(formItem)" size="medium" hero [disabled]="isProcessing">
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>{{'BarCode scan' | translate | headtitlecase}}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12" style="text-align: right;">

                            </div>
                        </div>
                        <!-- End Detail form -->

                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
                            <nb-icon on b-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save()" [nbSpinner]="isProcessing">{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>