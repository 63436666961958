<div class="dialog-wrap" #dialogWrap>
  <nb-card *ngFor="let data of this.data" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
    <nb-card-header>
      <ngx-card-header [size]="size" [icon]="favicon" [title]="title ? title : ('Sales.PriceTableOfPrinting.title' | translate:{definition: '', action: commonService.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

      <!-- <nav>
        {{'Sales.PriceTable.title' | translate:{action:commonService.translate.instant('Common.preview'), definition:''} | headtitlecase}}
        <button nbButton status="danger" hero size="tiny" (click)="close()" style="float: right;"
          title="{{'Common.goback' | translate | headtitlecase}}">
          <nb-icon pack="eva" icon="close"></nb-icon>{{'Common.close' | translate | headtitlecase}}
        </button>
        <button nbButton status="primary" hero size="tiny" (click)="print()" style="float: right;"
          title="{{'Common.print' | translate | headtitlecase}}">
          <nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
        </button>
      </nav> -->
    </nb-card-header>
    <nb-card-body #printContent>
      <div id="print-area">
        <!-- <div style="display: flex; flex-wrap: wrap">
          <div style="flex: 0 0 60%;">
            <pre>{{'Infomation.Voucher.register' | translate }}</pre>
          </div>
          <div style="text-align: right; flex: 0 0 40%;">
            <img [src]="env.register.logo.voucher" style="height: 50px;">
          </div>
        </div> -->
        <!-- <div>
          <div class="print-title">{{ 'Sales.PriceTable.title' | translate:{action: '', definition: ''} | uppercase }}
          </div>
          <div class="under-title">{{ data.Code }} - {{ data.DateOfApprove | date:'short' }}</div>
        </div> -->
        <div>
          <div class="under-line">{{renderValue(data.Title)}}
          </div>
        </div>
        <div>
          <div class="row details page-break-after" *ngFor="let row of detailRows">
            <ng-container *ngFor="let detail of row; let i = index">
              <div class="col-sm-3">
                <div class="grid-item">
                  <div class="image" style="background-position: center; background-repeat: no-repeat;"
                    [ngStyle]="{backgroundImage: 'url(\''+detail.FeaturePictureLarge + '?token='+apiService.getAccessToken()+'\')'}">
                  </div>
                  <div class="price">{{detail.Price | currency:'VND'}}<span class="unit">1 {{commonService.getObjectText(detail.Unit)}}</span></div>
                  <div class="name">{{detail.Name}}</div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div>
          <div class="under-line" *ngIf="data.Description">{{'Common.note' | translate | headtitlecase}}: {{renderValue(data.Description)}}</div>
          <!-- <div class="under-line">{{'Sales.deliveryAddress' | translate | headtitlecase}}:
            {{renderValue(data.DeliveryAddress)}}</div> -->
          <!-- <div class="under-line">{{'Sales.directReceiver' | translate | headtitlecase}}:
            {{renderValue(data.DirectReceiverName)}}</div> -->
        </div>
        <!-- <div style="display: flex; flex-wrap: wrap">
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            {{'Common.employee' | translate | headtitlecase}}
            <br><br><br>
          </div>
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            {{'Common.customer' | translate | headtitlecase}}
            <br><br><br>
          </div>
        </div> -->
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="buttons-row">
        <button nbButton hero status="primary" (click)="print(0);" [disabled]="!identifier">
          <nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="danger" (click)="saveAndClose();">
          <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="warning"
          (click)="exportExcel('excel');">{{'Common.exportTo' | translate:{to: 'Excel'} | headtitlecase}}</button>
        <button nbButton hero status="info"
          (click)="exportExcel('pdf');">{{'Common.exportTo' | translate:{to: 'PDF'} | headtitlecase}}</button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>
