<nb-card size="large">
  <nb-tabset fullWidth>

    <nb-tab tabTitle="Temperature">

      <div class="slider-container">
        <ngx-temperature-dragger [(value)]="temperature" (power)="temperatureOff = !$event"
                                 [min]="temperatureData.min" [max]="temperatureData.max" [disableArcColor]="theme.arcEmpty"
                                 [fillColors]="theme.arcFill" [thumbBg]="theme.thumbBg" [thumbBorderColor]="theme.thumbBorder">

          <div class="slider-value-container"  [ngClass]="{ 'off': temperatureOff }">
            <div class="value temperature h1">
              {{ temperatureOff ? '--' : (temperature | ngxRound) }}
            </div>
            <div class="desc">
              Celsius
            </div>
          </div>
        </ngx-temperature-dragger>
      </div>

      <nb-radio-group [(ngModel)]="temperatureMode" name="temperature-mode">
        <nb-radio value="cool">
          <i class="nb-snowy-circled"></i>
        </nb-radio>
        <nb-radio value="warm">
          <i class="nb-sunny-circled"></i>
        </nb-radio>
        <nb-radio value="heat">
          <i class="nb-flame-circled"></i>
        </nb-radio>
        <nb-radio value="fan">
          <i class="nb-loop-circled"></i>
        </nb-radio>
      </nb-radio-group>
    </nb-tab>

    <nb-tab tabTitle="Humidity">

      <div class="slider-container">
        <ngx-temperature-dragger [(value)]="humidity" (power)="humidityOff = !$event"
                                 [min]="humidityData.min" [max]="humidityData.max" [disableArcColor]="theme.arcEmpty"
                                 [fillColors]="theme.arcFill" [thumbBg]="theme.thumbBg" [thumbBorderColor]="theme.thumbBorder">

          <div class="slider-value-container"  [ngClass]="{ 'off': humidityOff }">
            <div class="value humidity h1">
              {{ humidityOff ? '--' : (humidity | ngxRound) }}
            </div>
          </div>
        </ngx-temperature-dragger>
      </div>

      <nb-radio-group [(ngModel)]="humidityMode" name="humidity-mode">
        <nb-radio value="cool">
          <i class="nb-snowy-circled"></i>
        </nb-radio>
        <nb-radio value="warm">
          <i class="nb-sunny-circled"></i>
        </nb-radio>
        <nb-radio value="heat">
          <i class="nb-flame-circled"></i>
        </nb-radio>
        <nb-radio value="fan">
          <i class="nb-loop-circled"></i>
        </nb-radio>
      </nb-radio-group>
    </nb-tab>
  </nb-tabset>
</nb-card>
