<nb-card size="giant" class="same-height-chart">
  <nb-tabset fullWidth>

    <nb-tab tabTitle="TOP 10 CTV bán hàng">
      <nb-list class="scrollable-container">
        <nb-list-item class="contact" *ngFor="let c of publishers">
          <nb-user [picture]="c.Avatar?.Thumbnail" [name]="c.Name" [title]="c.Publisher" size="large" style="flex: 8"></nb-user>
          <span class="caption" style="flex: 1;">{{ c.SumOfOrderDetail | number }}đơn</span>
          <nb-icon style="flex: 1" style="cursor: pointer" icon="message-circle-outline" pack="eva" nbTooltip="Tạo task trao đổi với {{c.Name}}" nbTooltipStatus="info" (click)="createTask($event, c)"></nb-icon>
        </nb-list-item>
      </nb-list>
    </nb-tab>

    <nb-tab tabTitle="TOP 10 sản phẩm sản lượng nhiều">
      <nb-list class="scrollable-container">
        <nb-list-item class="contact" *ngFor="let c of products">
          <nb-user style="flex: 9;" [picture]="c.FeaturePicture?.Thumbnail" [name]="c.Name" [title]="c.Product" size="large"></nb-user>
          <span style="flex: 1;" class="caption">{{ c.SumOfOrderDetail | number }}đơn</span>
        </nb-list-item>
      </nb-list>
    </nb-tab>

  </nb-tabset>
</nb-card>