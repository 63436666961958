<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="popup dialog-wrap">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon"
            [title]="title ? title : ('Warehouse.GoodsContainer.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)"
            [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <div class="col-md-3">
                <div class="row">
                  <div class="col-md-12">
                    <ngx-form-group [formGroup]="formItem" name="Warehouse" label="Common.warehouse" [array]="array"
                      [index]="i" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                      <ngx-select2 formControlName="Warehouse" [select2Option]="select2OptionForWarehouse" [data]="warehouseList"  (selectChange)="onWarehouseChange(formItem, $event, i)">
                      </ngx-select2>
                    </ngx-form-group>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Parent" label="Vị trí" [array]="array" [index]="i"
                  [allowCopy]="true" [required]="false">
                  <ngx-select2 formControlName="Parent" [select2Option]="getSelect2OptionForParent(i, formItem)" (click)="onParentClick($event, formItem)" >
                  </ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-md-3">
                  <ngx-form-group [formGroup]="formItem" name="Type" label="Common.type" [array]="array"
                    [index]="i" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                    <ngx-select2 formControlName="Type" [select2Option]="select2OptionForType" [data]="select2OptionForType.data">
                    </ngx-select2>
                  </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Code" label="Common.id" [array]="array" [index]="i"
                  [allowCopy]="true" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'Tạo tự động' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Name" label="Common.name" [array]="array" [index]="i"
                  [allowCopy]="true" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="Name"
                    placeholder="{{'Common.name' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="AccAccount" label="Warehouse.account" [array]="array" [index]="i"
                  [allowCopy]="true" [required]="false" [touchedValidate]="false">
                    <ngx-select2 formControlName="AccAccount" [select2Option]="select2OptionForAccAccount" [data]="accountList">
                      </ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="FindOrder" label="Số nhận thức" [array]="array" [index]="i"
                  [allowCopy]="true" [required]="false" [touchedValidate]="false">
                  <input type="text" nbInput fullWidth formControlName="FindOrder"
                    placeholder="{{'Số nhận thức' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array"
                  [index]="i" [allowCopy]="true" [required]="false">
                  <textarea nbInput fullWidth formControlName="Description"
                    placeholder="{{'Common.description' | translate | headtitlecase}}"></textarea>
                </ngx-form-group>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero style="float: left;">Thêm cái
              nữa</button>
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero
              (click)="saveAndClose()">Lưu & Đóng</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
