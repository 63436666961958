<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Accounting.CashPaymentVoucher.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)"
                        [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <input type="hidden" formControlName="Code">
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="Object" label="Common.Object.title" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                                    <ngx-select2 formControlName="Object" [select2Option]="select2OptionForContact" (selectChange)="onObjectChange(formItem, $event, i)"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-6">
                                <ngx-form-group [formGroup]="formItem" name="ObjectName" label="Common.Object.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                                    <input type="text" nbInput fullWidth formControlName="ObjectName" placeholder="{{'Common.Object.name' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="ObjectPhone" label="Common.Object.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ObjectPhone" placeholder="{{formItem.get('ObjectPhone').placeholder || ('Common.Object.phone' | translate | headtitlecase)}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="Page" label="Collaborator.Page.label" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [customIcons]="objectControlIcons">
                                    <ngx-select2 formControlName="Page" [select2Option]="select2OptionForPage" [data]="this.collaboratorService?.pageList$?.value"></ngx-select2>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-8">
                                <ngx-form-group [formGroup]="formItem" name="ObjectAddress" label="Common.Object.address" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ObjectAddress" placeholder="{{formItem.get('ObjectAddress').placeholder || ('Common.Object.address' | translate | headtitlecase)}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="ObjectEmail" label="Common.Object.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ObjectEmail" placeholder="{{formItem.get('ObjectEmail').placeholder || ('Common.Object.email' | translate | headtitlecase)}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="ObjectTaxCode" label="Common.Object.taxCode" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" nbInput fullWidth formControlName="ObjectTaxCode" placeholder="{{formItem.get('ObjectTaxCode').placeholder || ('Common.Object.taxCode' | translate | headtitlecase)}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-8">
                                <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                                    <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="DateOfVoucher" label="Accounting.dateOfPayment" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <input type="text" [owlDateTime]="DateOfVoucher" [owlDateTimeTrigger]="DateOfVoucher" nbInput fullWidth formControlName="DateOfVoucher" placeholder="{{'Accounting.dateOfPayment' | translate | headtitlecase}}">
                                    <owl-date-time #DateOfVoucher></owl-date-time>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-2">
                                <ngx-form-group [formGroup]="formItem" name="RelativeVouchers" label="Common.relationVoucher" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <button class="fit-control-button" [outline]="true" nbButton status="primary" fullWidth (click)="openRelativeVoucherChoosedDialog(formItem)" size="medium" hero>
                                        <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>Thêm chứng từ liên quan
                                    </button>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label class="label"><span class="valid">{{'Common.relativeVoucher' | translate | headtitlecase}}: </span></label>
                                    <div>
                                        <span class="tag" *ngFor="let relationVoucher of formItem.get('RelativeVouchers').value" nbTooltip="{{relationVoucher.type}}: {{relationVoucher | objectstext}}">
                                            <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher | objectid}}</span>
                                            <nb-icon pack="eva" icon="close-outline" class="close-btn" (click)="removeRelativeVoucher(formItem, relationVoucher)"></nb-icon>
                                        </span>
                                    </div>
                                    <div class="break"></div>
                                </div>
                            </div>
                        </div>


                        <hr>
                        <label class="label">{{'Common.details' | translate | headtitlecase}}</label>
                        <div class="row fit-row form-detail-header">
                            <div class="fit-fist-col label column">Stt</div>
                            <!-- <div class="row"> -->
                            <!-- <div class="col-sm-12"> -->
                            <div class="row fit-content-column">
                                <!-- <div class="col-sm-3 label column">{{'Common.accountingBusiness' | translate | headtitlecase}}</div> -->
                                <!-- <div class="col-sm-2 label column">{{'Accounting.debitAccount' | translate | headtitlecase}}</div>
                                <div class="col-sm-2 label column">{{'Accounting.creditAccount' | translate | headtitlecase}}</div> -->
                                <div class="col-sm-9 label column">{{'Common.description' | translate | headtitlecase}}</div>
                                <div class="col-sm-3 label column" style="text-align: right;">{{'Common.numOfMoney' | translate | headtitlecase}}</div>
                            </div>
                            <!-- </div> -->
                            <!-- </div> -->
                            <div class="fit-last-col column">
                                <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addDetailFormGroup(formItem)" size="medium" hero>
                                    <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                                </button>
                            </div>
                        </div>
                        <div formArrayName="Details" class="form-details">
                            <div class="form-detail-item" *ngFor="let detail of getDetails(formItem).controls; let ir=index" [formGroup]="detail">
                                <div class="row fit-row">
                                    <div class="fit-fist-col label">
                                        <div class="form-group">
                                            <label class="label">Stt</label>
                                            <input class="fit-sequence" nbInput fullWidth disabled="true" value="{{ ir + 1 }}">
                                            <input type="hidden" formControlName="Id">
                                        </div>
                                    </div>
                                    <div class="row fit-content-column">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <!-- <div class="col-sm-3">
                                                    <ngx-form-group [formGroup]="detail" name="AccountingBusiness" label="Common.accountingBusiness" [array]="getDetails(formItem)" [index]="ir" [allowCopy]="true" [required]="false" [hideLabel]="true" [customIcons]="customIcons">
                                                        <ngx-select2 formControlName="AccountingBusiness" [select2Option]="select2OptionForAccountingBusiness" [data]="accountingBusinessList" (selectChange)="onAccBusinessChange(detail, $event, ir)"></ngx-select2>
                                                    </ngx-form-group>
                                                </div> -->
                                                <!-- <div class="col-sm-2">
                                                    <ngx-form-group [formGroup]="detail" name="DebitAccount" label="Accounting.debitAccount" [array]="getDetails(formItem)" [index]="ir" [allowCopy]="true" [required]="false" [hideLabel]="true">
                                                        <ngx-select2 formControlName="DebitAccount" [select2Option]="select2ForAccount" [data]="accountDebitList"></ngx-select2>
                                                    </ngx-form-group>
                                                </div>
                                                <div class="col-sm-2">
                                                    <ngx-form-group [formGroup]="detail" name="CreditAccount" label="Accounting.creditAccount" [array]="getDetails(formItem)" [index]="ir" [allowCopy]="true" [required]="false" [hideLabel]="true">
                                                        <ngx-select2 formControlName="CreditAccount" [select2Option]="select2ForReciprocalAccount" [data]="formItem.creditAccounts"></ngx-select2>
                                                    </ngx-form-group>
                                                </div> -->
                                                <div class="col-sm-9">
                                                    <ngx-form-group [formGroup]="detail" name="Description" label="Common.description" [array]="getDetails(formItem)" [index]="ir" [allowCopy]="true" [required]="true" [hideLabel]="true">
                                                        <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                                                    </ngx-form-group>
                                                </div>
                                                <div class="col-sm-3">
                                                    <ngx-form-group [formGroup]="detail" name="Amount" label="Common.amount" [array]="getDetails(formItem)" [index]="ir" [allowCopy]="false" [align]="'rignt'" [required]="true" [hideLabel]="true">
                                                        <input type="text" (keyup)="toMoney(formItem)" nbInput fullWidth formControlName="Amount" placeholder="{{'Common.amount' | translate | headtitlecase}}" currencyMask [options]="curencyFormat" style="text-align: right;">
                                                    </ngx-form-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="fit-last-col">
                                        <div class="form-group" style="text-align: right;">
                                            <label class="label">CM</label>
                                            <button class="fit-control-button" nbButton status="danger" hero size="medium" (click)="removeDetail(formItem, ir)">
                                                <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-detail-item">
                                <div class="row fit-row form-detail-footer">
                                    <div class="fit-fist-col label">

                                    </div>
                                    <div class="row fit-content-column">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="col-sm-4">

                                                </div>
                                                <div class="col-sm-6" style="text-align: right; font-weight: bold;">Tổng cộng:</div>
                                                <div class="col-sm-2" style="text-align: right; font-weight: bold; padding-right: 1.7rem;">
                                                    {{formItem.get('_total').value | currency:'VND'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="fit-last-col">
                                        <div class="form-group" style="text-align: right;">
                                            <label class="label">CM</label>
                                            <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addDetailFormGroup(formItem)" size="medium" hero>
                                                <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <!-- <button nbButton status="warning" (click)="addFormGroup()" hero style="float: left;">Thêm cái nữa</button> -->
                        <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
                        <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>