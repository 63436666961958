<div class="dialog-wrap {{inputMode}}" #dialogWrap>
  <nb-card *ngFor="let data of this.data" style="max-height: initial; margin: 0 auto; width: 22cm" class="small-header">
    <nb-card-header>
      <ngx-card-header [size]="size" [icon]="favicon" [title]="title ? title : ('Sales.PriceReport.title' | translate:{definition: '', action: commonService.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

      <!-- <nav>
        {{'Sales.PriceTable.title' | translate:{action:commonService.translate.instant('Common.preview'), definition:''} | headtitlecase}}
        <button nbButton status="danger" hero size="tiny" (click)="close()" style="float: right;"
          title="{{'Common.goback' | translate | headtitlecase}}">
          <nb-icon pack="eva" icon="close"></nb-icon>{{'Common.close' | translate | headtitlecase}}
        </button>
        <button nbButton status="primary" hero size="tiny" (click)="print()" style="float: right;"
          title="{{'Common.print' | translate | headtitlecase}}">
          <nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
        </button>
      </nav> -->
    </nb-card-header>
    <nb-card-body class="print-body" #printContent>
      <div id="print-area">
        <ngx-print-header></ngx-print-header>
        <div>
          <div class="print-title">{{ 'Sales.PriceTable.title' | translate:{action: '', definition: ''} | uppercase }}
          </div>
          <div class="under-title">{{ data.Code }} - {{ data.DateOfApprove | date:'short' }}</div>
        </div>
        <div>
          <div class="under-line">{{'Voucher.dearCustomer' | translate | headtitlecase}} :
            {{renderValue(data.SupplierName)}}
          </div>
          <div class="under-line">{{'Common.address' | translate | headtitlecase}} : {{renderValue(data.SupplierAddress)}}
          </div>
        </div>
        <div>
          <div>
            <table style="width: 100%;" class="print-voucher-detail-table">
              <colgroup>
                <!--STT-->
                <col span="1" style="width: 1cm;">
                <!--Dien giai-->
                <col span="1" style="width: 5.5cm;">
                <!--DVT-->
                <col span="1" style="width: 1cm;">
                <!--So luong-->
                <!-- <col span="1" style="width: 2cm; text-align: right;"> -->
                <!--Don gia-->
                <col span="1" style="width: 2.8cm; text-align: right;">
                <!--Thue-->
                <col span="1" style="width: 2cm;">
                <!--Thanh tien-->
                <!-- <col span="1" style="width: 2.7cm; text-align: right;"> -->
              </colgroup>
              <tr class="print-voucher-detail-header">
                <th>{{'Common.noNumber' | translate | headtitlecase}}</th>
                <th>{{'Sales.technicalDescription' | translate | headtitlecase}}</th>
                <th class="text-align-right">{{'Voucher.shortUnit' | translate | headtitlecase}}</th>
                <!-- <th class="text-align-right">{{'Voucher.shortQuantity' | translate | headtitlecase}}</th> -->
                <th class="text-align-right">{{'Voucher.price' | translate | headtitlecase}}</th>
                <th class="text-align-right">{{'Voucher.tax' | translate | headtitlecase}}</th>
                <!-- <th class="text-align-right">{{'Voucher.toMoney' | translate | headtitlecase}}</th> -->
              </tr>
              <tr class="print-voucher-detail-line" *ngFor="let detail of data.Details; let i = index">
                <td>{{i + 1}}</td>
                <td>{{renderValue(detail.Note)}} <br>
                  <span style="font-size: 10px; font-style: italic;">
                    {{'Common.note' | translate}} : {{detail.Product?.Code}},
                    {{'Common.code' | translate}} : {{detail.Product?.Code}},
                    {{'Common.sort' | translate | headtitlecase}}: {{detail.No}}
                  </span>
                </td>
                <td class="text-align-right">{{detail.Unit?.Name}}</td>
                <!-- <td class="text-align-right">{{renderValue(detail.Quantity) | number}}</td> -->
                <td class="text-align-right">{{renderValue(detail.Price) | currency:'VND'}}</td>
                <td class="text-align-right">{{detail.Tax?.Label2}}</td>
                <!-- <td class="text-align-right">{{toMoney(detail) | currency:'VND'}}</td> -->
              </tr>
              <!-- <tr>
                <td colspan="6" class="text-align-right">{{'Voucher.total' | translate | headtitlecase}}</td>
                <td class="text-align-right">{{getTotal() | currency:'VND'}}</td>
              </tr> -->
            </table>
          </div>
        </div>
        <div>
          <div class="under-line">{{'Common.note' | translate | headtitlecase}}: {{renderValue(data.Description)}}</div>
          <!-- <div class="under-line">{{'Sales.deliveryAddress' | translate | headtitlecase}}:
            {{renderValue(data.DeliveryAddress)}}</div> -->
          <!-- <div class="under-line">{{'Sales.directReceiver' | translate | headtitlecase}}:
            {{renderValue(data.DirectReceiverName)}}</div> -->
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            {{'Common.employee' | translate | headtitlecase}}
            <br><br><br>
          </div>
          <div style="text-align: center; flex: 0 0 50%;">
            <br>
            {{'Common.customer' | translate | headtitlecase}}
            <br><br><br>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="buttons-row">
        <button nbButton hero status="primary" (click)="print();" [disabled]="!identifier">
          <nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="danger" (click)="saveAndClose();">
          <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
        </button>
        <button nbButton hero status="warning"
          (click)="exportExcel('excel');">{{'Common.exportTo' | translate:{to: 'Excel'} | headtitlecase}}</button>
        <button nbButton hero status="info"
          (click)="exportExcel('pdf');">{{'Common.exportTo' | translate:{to: 'PDF'} | headtitlecase}}</button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>
