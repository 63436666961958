<nb-card>
  <nb-card-header class="header">My Playlist</nb-card-header>

  <nb-card-body class="body">
    <div class="track-info">
      <div class="cover" style.background-image="url('{{track.cover}}')"></div>
      <div class="details">
        <h4 [class.subtitle]="collapsed">{{ track.name }}</h4>
        <span>{{ track.artist }}</span>
      </div>
    </div>

    <div class="progress-wrap">
      <input dir="ltr" type="range" class="progress" [value]="getProgress()" min="0" max="100" step="0.01"
             (input)="setProgress(duration.value)" #duration>
      <div class="progress-foreground" [style.width.%]="getProgress()"></div>
    </div>

    <div class="timing">
      <small class="current">{{ player.currentTime | timing }}</small>
      <small class="remaining">- {{ player.duration - player.currentTime | timing }}</small>
    </div>

    <div class="controls">
      <button class="control-button" nbButton ghost size="tiny" (click)="toggleShuffle()" [class.on]="shuffle">
        <nb-icon icon="shuffle-2-outline" pack="eva"></nb-icon>
      </button>
      <button class="control-button" nbButton ghost size="medium" (click)="prev()">
        <nb-icon class="skip" icon="skip-back-outline" pack="eva"></nb-icon>
      </button>
      <button class="control-button play-button" nbButton ghost size="medium" (click)="playPause()">
        <nb-icon class="play" [icon]="player.paused ? 'play-circle-outline' : 'pause-circle-outline'" pack="eva">
        </nb-icon>
      </button>
      <button class="control-button skip-forward-button" nbButton ghost size="medium" (click)="next()">
        <nb-icon class="skip" icon="skip-forward-outline" pack="eva"></nb-icon>
      </button>
      <button class="control-button" nbButton ghost size="tiny" (click)="toggleLoop()" [class.on]="player.loop">
        <nb-icon icon="repeat-outline" pack="eva"></nb-icon>
      </button>
    </div>
  </nb-card-body>

  <nb-card-footer class="footer">
    <div class="volume">
      <button nbButton ghost size="small" (click)="setVolume(0)">
        <nb-icon class="volume-icon" icon="volume-down-outline" pack="eva"></nb-icon>
      </button>
      <div class="progress-wrap">
        <input type="range" class="progress" [value]="getVolume()" max="100"
               (input)="setVolume(volume.value)" #volume>
        <div class="progress-foreground" [style.width.%]="getVolume()"></div>
      </div>
      <button nbButton ghost size="small" (click)="setVolume(100)">
        <nb-icon class="volume-icon" icon="volume-up-outline" pack="eva"></nb-icon>
      </button>
    </div>
  </nb-card-footer>
</nb-card>
