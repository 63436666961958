<div class="dialog-wrap {{inputMode}}">
    <nb-card class="small-header smart-table">
        <nb-card-header>
            <ngx-card-header [size]="size" [icon]="favicon" [title]="title ? title : (('Accounting.MasterBook.headAmount' | translate | headtitlecase))" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>

        <nb-card-body>
            <div class="ng2-smart-table-wrap">
                <ng2-smart-table *ngIf="settings" [attr.select]="hasSelect" #table [settings]="settings" [source]="source" (delete)="delete($event)" (edit)="onEditAction($event)" (create)="onSerialAction($event)" (userRowSelect)="onUserRowSelect($event)" (rowSelect)="onRowSelect($event)">
                </ng2-smart-table>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <div class="buttons-row" style="text-align: right;">
                <button nbButton status="primary" [disabled]="loading" style="float: right;" hero (click)="saveAndClose() && false">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                <button nbButton status="success" [disabled]="loading" style="float: right;" hero (click)="save() && false">{{'Common.save' | translate | headtitlecase}}</button>
            </div>
        </nb-card-footer>
    </nb-card>
</div>