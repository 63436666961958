<div class="dialog-wrap {{inputMode}}" #dialogWrap>
    <nb-card *ngFor="let data of this.data; let i=index" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
        <nb-card-header>
            <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="data['Title'] || ('ĐỐI SOÁT CÔNG NỢ' | translate:{definition: '', action: commonService.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body class="print-body" #printContent>
            <div id="print-area">
                <ngx-print-header></ngx-print-header>
                <div>
                    <div class="print-title">{{ this.title | uppercase }}</div>
                    <div class="under-title">Từ {{data.FromDate | date: 'short'}} đến {{ data.ToDate | date:'short' }}</div>
                    <div class="under-title">Ngày giờ báo cáo: {{ data.ReportDate | date: 'short'}}</div>
                </div>
                <div>
                    <div style="display: flex;">
                        <div style="flex: 1;">
                            <div class="under-line">{{'Kính gửi quý đối tác (Bên A)' | translate | headtitlecase}} :
                                {{renderValue(data.ObjectName)}}
                            </div>
                        </div>
                        <div>
                            <div class="under-line">{{'ID đối tác' | translate | headtitlecase}} :
                                {{renderValue(data.Object | objectid)}}
                            </div>
                        </div>
                    </div>
                    <div style="display: flex;">
                        <div style="flex: 1;">
                            <div class="under-line">{{'Common.phone' | translate | headtitlecase}} :
                                {{renderValue(data.ObjectPhone)}}
                            </div>
                        </div>
                        <div>
                            <div class="under-line">{{'Common.email' | translate | headtitlecase}} :
                                {{renderValue(data.ObjectEmail)}}
                            </div>
                        </div>
                    </div>
                    <div class="under-line">{{'Common.address' | translate | headtitlecase}} :
                        {{renderValue(data.ObjectAddress)}}
                    </div>
                </div>
                <div>
                    <div>
                        <table style="width: 100%;" class="print-voucher-detail-table ">
                            <thead class="print-voucher-detail-header">
                                <td>#</td>
                                <td class="" style="white-space: nowrap">{{'Common.dateOfVoucher' | translate | headtitlecase}}</td>
                                <td class="" style="white-space: nowrap">{{'Common.voucher' | translate | headtitlecase}}</td>
                                <td class="" style="white-space: nowrap">{{'Common.description' | translate | headtitlecase}}</td>
                                <td class="" style="white-space: nowrap">{{'Accounting.account' | translate | headtitlecase}}</td>
                                <td class="text-align-right" style="white-space: nowrap">{{'Accounting.debitGenerate' | translate | headtitlecase}}</td>
                                <td class="text-align-right" style="white-space: nowrap">{{'Accounting.creditGenerate' | translate | headtitlecase}}</td>
                                <td class="text-align-right" style="white-space: nowrap">{{'Accounting.tailDebit' | translate | headtitlecase}}</td>
                                <td class="text-align-right" style="white-space: nowrap">{{'Accounting.tailCredit' | translate | headtitlecase}}</td>
                            </thead>
                            <tr class="print-voucher-detail-line" *ngFor="let detail of data.Details; let i = index">
                                <td>{{i + 1}}</td>
                                <td class="nowrap">{{detail.VoucherDate | date:'short'}}</td>
                                <td>{{detail.Voucher}}</td>
                                <td>{{detail.Voucher === 'OPN' && 'Đầu kỳ' || detail.VoucherDescription || detail.Description}}</td>
                                <td>{{detail.Account}}</td>
                                <td class="text-align-right" [innerHtml]="renderCurrency(detail.GenerateDebit)"></td>
                                <td class="text-align-right" [innerHtml]="renderCurrency(detail.GenerateCredit)"></td>
                                <td class="text-align-right" [innerHtml]="renderCurrency(detail.DebitIncrementAmount)"></td>
                                <td class="text-align-right" [innerHtml]="renderCurrency(detail.CreditIncrementAmount)"></td>
                            </tr>
                            <tr style="font-weight: bold;">
                                <td colspan="5" class="text-align-right">Tổng cộng: </td>
                                <td class="text-align-right" [innerHtml]="renderCurrency(data.TotalDebit)"></td>
                                <td class="text-align-right" [innerHtml]="renderCurrency(data.TotalCredit)"></td>
                                <td class="text-align-right" [innerHtml]="renderCurrency(data.TailTotalDebit)"></td>
                                <td class="text-align-right" [innerHtml]="renderCurrency(data.TailTotalCredit)"></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="display: flex; flex-wrap: wrap">
                    <div style="text-align: center; flex: 0 0 33.333%;">
                        <br/>Kế toán<br/><br/><br/>
                    </div>
                    <div style="text-align: center; flex: 0 0 33.33%;">
                        <br/>Bên A <br/>({{data.ObjectName}})<br/><br/><br/>
                    </div>
                    <div style="text-align: center; flex: 0 0 33.33%;">
                        <br>Bên B <br/>({{systemConfigs?.LICENSE_INFO?.register?.companyName}})<br/><br/><br/>
                    </div>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <div class="buttons-row">
                <button nbButton hero status="danger" (click)="close();">
                    <nb-icon pack="eva" icon="close-outline"></nb-icon>ESC
                </button>
                <button nbButton hero status="primary" (click)="print(i);">
                    <nb-icon pack="eva" icon="printer-outline"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                </button>
                <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
            </div>
        </nb-card-footer>
    </nb-card>
</div>