<div class="dialog-wrap" #dialogWrap>
    <nb-card [nbSpinner]="loading" style="max-height: initial; margin: 0 auto;" class="small-header">
      <!-- <nb-card-header>{{ title }}</nb-card-header> -->
      <nb-card-header>
        <nav>
          {{ title }}
          <button nbButton status="danger" hero size="medium" (click)="dismiss()" style="float: right;" title="Trở về"><nb-icon pack="eva" icon="close-outline"></nb-icon>ESC</button>
        </nav>
      </nb-card-header>
      <nb-card-body>
        <ngx-image-viewer [src]="images" [(index)]="imageIndex"></ngx-image-viewer>
      </nb-card-body>
      <!-- <nb-card-footer>
        <div class="buttons-row">
          <button nbButton status="basic" (click)="dismiss()">Đóng</button>
        </div>
      </nb-card-footer> -->
    </nb-card>
  </div>