<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="dialog-wrap popup" style="width: 90%; margin: 0 auto;">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Accounting.Account.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <label class="label label-with-border-below">{{'Common.contactInfo' | translate | headtitlecase}}</label>
            <div class="row dashed-radius-border">
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Object" label="Voucher.organizationOrPersornal" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <ngx-select2 formControlName="Object" [select2Option]="select2ContactOption" (selectChange)="onObjectChange(formItem, $event, i)"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="ObjectName" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="ObjectName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="ObjectPhone" label="Common.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="ObjectPhone" placeholder="{{formItem.get('ObjectPhone').placeholder || ('Common.phone' | translate | headtitlecase)}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="ObjectIdentifiedNumber" label="Common.identifiedNumber" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="ObjectIdentifiedNumber" placeholder="{{'Common.identifiedNumber' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="ObjectAddress" label="Common.address" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="ObjectAddress" placeholder="{{formItem.get('ObjectAddress').placeholder || ('Common.address' | translate | headtitlecase)}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="ObjectEmail" label="Common.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="ObjectEmail" placeholder="{{formItem.get('ObjectEmail').placeholder || ('Common.email' | translate | headtitlecase)}}">
                </ngx-form-group>
              </div>
            </div>
            <label class="label label-with-border-below">{{'CommerceServiceByCycle.serviceInfo' | translate | headtitlecase}}</label>
            <div class="row dashed-radius-border">
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Product" label="Common.service" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="Product" placeholder="{{'Common.service' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <input type="hidden" nbInput fullWidth formControlName="ProductName" placeholder="{{'Common.serviceName' | translate | headtitlecase}}">
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="OriginVoucher" label="Common.originVoucher" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="OriginVoucher" placeholder="{{'Common.driginVoucher' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <input type="hidden" nbInput fullWidth formControlName="OriginVoucherType">
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Cycle" label="Common.cycle" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <ngx-select2 formControlName="Cycle" [select2Option]="select2CycleOption" [data]="cycleList" (selectChange)="onObjectChange(formItem, $event, i)"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="DateOfStart" label="Common.dateOfStart" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" [owlDateTime]="DateOfStart" [owlDateTimeTrigger]="DateOfStart" nbInput fullWidth formControlName="DateOfStart" placeholder="{{'Common.dateOfStart' | translate | headtitlecase}}">
                  <owl-date-time #DateOfStart></owl-date-time>
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Name" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{formItem.get('Name').placeholder || ('Common.name' | translate | headtitlecase)}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="DaysOfBeforeExpired" label="CommerceServiceByCycle.daysOfBeforeExpired" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="DaysOfBeforeExpired" placeholder="{{'Common.daysOfBeforeExpired' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="DaysOfAfterExpired" label="CommerceServiceByCycle.daysOfAfterExpired" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                  <input type="text" nbInput fullWidth formControlName="DaysOfAfterExpired" placeholder="{{'Common.daysOfAfterExpired' | translate | headtitlecase}}">
                </ngx-form-group>
              </div>
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <textarea nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}"></textarea>
                </ngx-form-group>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>{{'Common.save' | translate | headtitlecase}}</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>