<form class="popup">
  <nb-card class="small-header popup">
    <nb-card-header>
      <ngx-card-header [size]="size" [icon]="favicon" [title]="title ? title : ('AdminProduct.Category.title' | translate:{definition: '', action: commonService.translate.instant('Common.assign')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

      <!-- <nav>
        {{'AdminProduct.assignCategories' | translate | headtitlecase}}
        <button nbButton status="warning" hero size="tiny" style="float: right;" title="Đóng" (click)="close()">
          <nb-icon pack="eva" icon="close"></nb-icon>
        </button>
      </nav> -->
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="label"><span class="valid">{{'AdminProduct.categories' | translate | headtitlecase}}</span>
            </label>
            <ngx-select2 [formControl]="categoriesFormControl" [select2Option]="select2OptionForCategories" [data]="categoryList">
            </ngx-select2>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="buttons-row" style="text-align: right;">
        <button nbButton status="warning" hero>{{'Common.close' | translate | headtitlecase}}</button>
        <button type="button" nbButton status="primary" [disabled]="!categoriesFormControl.value || processing" hero
          (click)="assignCategories()">{{'Common.assign' | translate | headtitlecase}}</button>
        <button type="button" nbButton status="danger" [disabled]="!categoriesFormControl.value || processing" hero
          (click)="revokeCategories()">{{'Common.revoke' | translate | headtitlecase}}</button>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
