<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('AdminProduct.Unit.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật đơn vị tính
            <button nbButton status="danger" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="close-circle"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code_old">
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Code'), 'warning')">
                  <label class="label"><span class="valid">Mã</span><span class="invalid">Mã là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="Code" placeholder="Viết không dấu, in hoa và không có khoản trắng" style="text-transform:uppercase">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                  <label class="label"><span class="valid">Tên</span><span class="invalid">Tên là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('FullName'), 'warning')">
                  <label class="label"><span class="valid">Tên đầy đủ</span><span class="invalid">Tên đầy đủ là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="FullName" placeholder="Tên đầy đủ">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Symbol'), 'warning')">
                  <label class="label"><span class="valid">Ký hiệu</span><span class="invalid">Ký hiệu là bắt buộc
                      (*)</span></label>
                      <input type="text" nbInput fullWidth formControlName="Symbol" placeholder="Ký hiệu">
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero style="float: left;">Thêm cái nữa</button>
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
