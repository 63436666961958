import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-collaborator-publisher-report',
  templateUrl: './collaborator-publisher-report.component.html',
  styleUrls: ['./collaborator-publisher-report.component.scss']
})
export class CollaboratorPublisherReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
