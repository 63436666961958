<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="dialog-wrap popup" style="width: 90%; margin: 0 auto;">
    <div formArrayName="array">
      <div *ngFor="let formItem of array.controls; let i = index">
        <nb-card class="small-header popup">
          <nb-card-header>
            <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Cluster.AuthorizedKey.title' | translate:{definition: '', action: commonService.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
          </nb-card-header>
          <nb-card-body>
            <div [formGroup]="formItem">
              <div class="row">
                <div class="col-sm-12">
                  <ngx-form-group [formGroup]="formItem" name="Issuer" label="Common.issuer" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                    <input type="text" nbInput fullWidth formControlName="Issuer" placeholder="{{'Common.issuer' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-12">
                  <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                    <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-12">
                  <ngx-form-group [formGroup]="formItem" name="ApiUrl" label="Common.api" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                    <input type="text" nbInput fullWidth formControlName="ApiUrl" placeholder="{{'Common.api' | translate | headtitlecase}}">
                  </ngx-form-group>
                </div>
                <div class="col-sm-12">
                  <ngx-form-group [formGroup]="formItem" name="PublicKey" label="Common.authorizedKey" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                    <textarea nbInput fullWidth formControlName="PublicKey" placeholder="{{'Common.authorizedKey' | translate | headtitlecase}}"></textarea>
                  </ngx-form-group>
                </div>
                <div class="col-sm-12">
                  <ngx-form-group [formGroup]="formItem" name="IsEnabled" label="Common.enable" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                    <nb-checkbox formControlName="IsEnabled" title="{{'Common.enable' | translate | headtitlecase}}">{{'Common.enable' | translate | headtitlecase}}</nb-checkbox>
                  </ngx-form-group>
                </div>
              </div>
            </div>
          </nb-card-body>
          <nb-card-footer>
            <div class="buttons-row" style="text-align: right;">
              <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
              <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>{{'Common.save' | translate | headtitlecase}}</button>
            </div>
          </nb-card-footer>
        </nb-card>
  
      </div>
    </div>
  </form>